import { ZodType, z } from "zod";

export type Page = {
    id: number;
    description: string;
}

export type PageCreateRequest = {
    description: string;
}

export type PageUpdateRequest = {
    description: string;
}

export const PageCreateSchema: ZodType<PageCreateRequest> = z.object({
    description: z.string().min(2, {
        message: "Too short page description"
    })
})

export const PageUpdateSchema: ZodType<PageUpdateRequest> = z.object({
    description: z.string().min(2, {
        message: "Too short page description"
    })
})