import React from 'react'
import { getToken } from '@/utils/localStorage'
import { Navigate } from 'react-router-dom'

export interface ProtectedRouteProps {
    children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    
    const token = getToken()

    return token ? children : <Navigate to="/login" replace />
}

export default ProtectedRoute
