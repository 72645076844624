import React from 'react'

export interface LoadingSpinnerProps {
    className?: string
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className, variant = 'light' }) => {

  const defaultClassName = 'w-5 h-5 border-r-2 border-white-500 rounded-full animate-spin' 
  
  const getSpinnerColor = (variant: string) => {
    switch (variant) {
      case 'primary':
        return 'border-primary-500'
      case 'secondary':
        return 'border-secondary-500'
      case 'success':
        return 'border-green-500'
      case 'danger':
        return 'border-red-500'
      case 'warning':
        return 'border-yellow-500'
      case 'info':
        return 'border-blue-500'
      case 'dark':
        return 'border-gray-500'
      case 'light':
        return 'border-white-500'
      default:
        return 'border-white-500'
    }
  }

  const newClassName = `${defaultClassName} ${getSpinnerColor(variant)}`

  return (
    <svg className={className ? className: newClassName} viewBox="0 0 24 24"></svg>
  )
}

export default LoadingSpinner
