import React, { useEffect, useState } from 'react';
import { ResetPasswordRequest, ResetPasswordSchema } from '@/types/ResetPassword';
import GenericApi from '@/api/genericApi';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { ApiResponse, ApiError } from '@/types/Api';
import { ValidationErrors, FormError } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { formatError } from '@/utils/formatError';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { generateResetPasswordInputs } from '@/utils/generateInputs';
import FormField from '@/components/CustomForm/FormField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutesPathList } from '@/config/routes/Routes';
import { wait } from '@/utils/utils';
import { Logo } from '@/components';

const ResetPassword: React.FC = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState<string | null>(null);
    const [userId, setUserId] = useState<number | null>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<ResetPasswordRequest>({
        resolver: zodResolver(ResetPasswordSchema),
    });

    useEffect(() => {
        setToken(searchParams.get('token'))
        setUserId(Number(searchParams.get('user_id')))
    }, [searchParams])

    const resetPasswordMutation = useMutation<ApiResponse<unknown>, ApiError<ValidationErrors>, ResetPasswordRequest>(
        {
            mutationFn: (data) => GenericApi.put<unknown>(ApiRoutes.RESET_PASSWORD, {
                new_password: data.new_password, 
                token,
                user_id: userId
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                await wait(1000)
                navigate(RoutesPathList.Login, { replace: true })
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        const errorMessage = 'An error occurred while resetting the password'

        if (isAxiosError<FormError>(error)) {
            setError('root', {
                type: 'manual',
                message: error.response?.data?.detail ?? errorMessage
            })
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            setError('root', {
                type: 'manual',
                message: formatError(error, errorMessage)
            })
            return
        }

        //* Set error message from server
        setError('root', {
            type: 'manual',
            message: error?.message ?? errorMessage
        })
    }

    const onSubmit = (data: ResetPasswordRequest) => {
        resetPasswordMutation.mutate(data)
    }

    const inputStyle = "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
    const labelStyle = "block text-sm font-medium text-gray-700 dark:text-gray-300"
    const containerStyle = "flex justify-center flex-col w-full"
    const resetFields = generateResetPasswordInputs()

    return (
        <div className="bg-gray-50 dark:bg-gray-900 h-[100dvh]">
            <div className="max-w-screen-xl px-4 py-8 mx-auto">
                <div className="mb-6 text-center">
                    <Logo size={{
                        width: "200px",
                        height: "70px"
                    }} />
                </div>
                <div className="w-full col-span-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0">
                    <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl dark:text-white">
                            Create new password
                        </h1>
                        <p className="font-light text-gray-500 dark:text-gray-400">
                            Your new password must be different from previous used passwords.
                        </p>
                        {
                            resetPasswordMutation.isError ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{
                                    errors.root?.message
                                }</span>
                            </div> : null
                        }
                        {
                            resetPasswordMutation.isSuccess ?
                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                    <span className="block sm:inline">Please check your email!</span>
                                </div> : null
                        }
                        <form className="space-y-4 lg:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                            {
                                resetFields.map((field, index) => (
                                    <FormField<ResetPasswordRequest>
                                        key={index}
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        register={register}
                                        error={errors[field.name as keyof ResetPasswordRequest]}
                                        placeholder={field.placeholder}
                                        required={field.required}
                                        valueAsNumber={field.valueAsNumber}
                                        inputStyle={inputStyle}
                                        labelStyle={labelStyle}
                                        containerStyle={containerStyle}
                                    />
                                ))}
                            <button
                                type="submit"
                                className="w-full flex justify-center items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                {
                                    resetPasswordMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : 'Reset Password'
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
