import React from 'react';

const CreditCard: React.FC = () => {
    return (
        <svg
            cursor='pointer'
            className='credit-card mx-5'
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 496 496"
            xmlSpace="preserve"
            width={100}
            height={100}
        >
            <path
                style={{ fill: '#12DB55' }}
                d="M496,379.6c0,9.6-7.2,16.8-16.8,16.8H16.8c-9.6,0-16.8-7.2-16.8-16.8v-264  c0-9.6,7.2-16.8,16.8-16.8h462.4c9.6,0,16.8,7.2,16.8,16.8L496,379.6L496,379.6z"
            />
            <g>
                <path
                    style={{ fill: '#12DB55' }}
                    d="M496,379.6c0,9.6-7.2,16.8-16.8,16.8H16.8c-9.6,0-16.8-7.2-16.8-16.8v-264   c0-9.6,7.2-16.8,16.8-16.8h462.4c9.6,0,16.8,7.2,16.8,16.8L496,379.6L496,379.6z"
                />
            </g>
            <path
                style={{ fill: '#0AC945' }}
                d="M0,115.6c0-9.6,7.2-16.8,16.8-16.8h462.4c9.6,0,16.8,7.2,16.8,16.8v264.8c0,9.6-7.2,16.8-16.8,16.8"
            />
            <rect y="118.8" style={{ fill: '#334449' }} width="496" height="96.8" />
            <polygon points="170.4,215.6 496,215.6 496,118.8 5.6,118.8 " />
            <path
                style={{ fill: '#0CC69A' }}
                d="M496,379.6c0,9.6-7.2,16.8-16.8,16.8H16.8c-9.6,0-16.8-7.2-16.8-16.8"
            />
            <path
                style={{ fill: '#0BAF84' }}
                d="M479.2,396.4c9.6,0,16.8-7.2,16.8-16.8h-44.8L479.2,396.4z"
            />
            <g>
                <path
                    style={{ fill: '#D4F9ED' }}
                    d="M177.6,264.4c0,3.2-2.4,5.6-5.6,5.6H52.8c-3.2,0-5.6-2.4-5.6-5.6l0,0c0-3.2,2.4-5.6,5.6-5.6H172   C175.2,258.8,177.6,261.2,177.6,264.4L177.6,264.4z"
                />
                <path
                    style={{ fill: '#D4F9ED' }}
                    d="M177.6,293.2c0,3.2-2.4,5.6-5.6,5.6H52.8c-3.2,0-5.6-2.4-5.6-5.6l0,0c0.8-3.2,3.2-5.6,5.6-5.6H172   C175.2,287.6,177.6,290,177.6,293.2L177.6,293.2z"
                />
                <path
                    style={{ fill: '#D4F9ED' }}
                    d="M154.4,322c0,3.2-2.4,5.6-5.6,5.6h-96c-2.4,0-4.8-2.4-4.8-5.6l0,0c0-3.2,2.4-5.6,5.6-5.6h96   C152,317.2,154.4,319.6,154.4,322L154.4,322z"
                />
            </g>
            <circle style={{ fill: '#FFBC00' }} cx="360" cy="300.4" r="60" />
            <path
                style={{ fill: '#FFAA00' }}
                d="M360,240.4c-30.4,0-55.2,22.4-60,51.2l96.8,56.8c14.4-11.2,23.2-28,23.2-48  C420.8,266.8,393.6,240.4,360,240.4z"
            />
            <path
                style={{ fill: '#F7B208' }}
                d="M360,240.4c33.6,0,60,27.2,60,60s-27.2,60-60,60"
            />
            <g>
                <circle style={{ fill: '#F20A41' }} cx="408" cy="300.4" r="60" />
                <circle style={{ fill: '#F20A41' }} cx="408" cy="300.4" r="60" />
            </g>
            <path
                style={{ fill: '#E00040' }}
                d="M408,361.2c-33.6,0-60-27.2-60-60s27.2-60,60-60"
            />
            <path
                style={{ fill: '#F97803' }}
                d="M384,245.2c-21.6,9.6-36,30.4-36,55.2s15.2,46.4,36,55.2c21.6-9.6,36-30.4,36-55.2  S405.6,254,384,245.2z"
            />
            <path
                style={{ fill: '#F76806' }}
                d="M384,355.6c21.6-9.6,36-30.4,36-55.2s-15.2-46.4-36-55.2"
            />
        </svg>
    );
};

export default CreditCard;
