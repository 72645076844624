import { z, ZodSchema } from "zod";
import { Company } from "../Company";
import { Category } from "../Settings/Categories";

export type Document = {
  id: number;
  company: number;
  type: "invoice" | "bill";
  document_number: string;
  order_number?: string | null;
  status?: string | null;
  issued_at: string;
  due_at: string;
  amount: number;
  currency: string;
  currency_rate?: number | null;
  discount?: number | null;
  discount_type?: "percentage" | "fixed" | null;
  category: string;
  contact: string;

  title?: string | null;
  subheading?: string | null;
  notes?: string | null;
};

export type DocumentCreateRequest = {
  type: "invoice" | "bill";
  document_number: string;
  order_number?: string | null;
  status?: string | null;
  issued_at: string;
  due_at: string;
  amount: number;
  currency: string;
  currency_rate?: number | null;
  discount?: number | null;
  discount_type?: "percentage" | "fixed" | null;
  category: string;
  contact: string;

  title?: string | null;
  subheading?: string | null;
  notes?: string | null;
};

export type DocumentUpdateRequest = {
  type: "invoice" | "bill";
  document_number: string;
  order_number?: string | null;
  status?: string | null;
  issued_at: string;
  due_at: string;
  amount: number;
  currency: string;
  currency_rate?: number | null;
  discount?: number | null;
  discount_type?: "percentage" | "fixed" | null;
  category: string;
  contact: string;

  title?: string | null;
  subheading?: string | null;
  notes?: string | null;
};


export const DocumentCreateSchema: ZodSchema<DocumentCreateRequest> = z.object({
    type: z.enum(["invoice", "bill"]),
    document_number: z.string(),
    order_number: z.string().optional(),
    status: z.string().optional(),
    issued_at: z.string(),
    due_at: z.string(),
    amount: z.number(),
    currency: z.string(),
    currency_rate: z.number().optional(),
    discount: z.number().optional(),
    discount_type: z.enum(["percentage", "fixed"]).optional(),
    category: z.string(),
    contact: z.string(),
    title: z.string().optional(),
    subheading: z.string().optional(),
    notes: z.string().optional(),
});


export const DocumentUpdateSchema: ZodSchema<DocumentUpdateRequest> = z.object({
  type: z.enum(["invoice", "bill"]),
  document_number: z.string(),
  order_number: z.string().optional(),
  status: z.string().optional(),
  issued_at: z.string(),
  due_at: z.string(),
  amount: z.number(),
  currency: z.string(),
  currency_rate: z.number().optional(),
  discount: z.number().optional(),
  discount_type: z.enum(["percentage", "fixed"]).optional(),
  category: z.string(),
  contact: z.string(),
  title: z.string().optional(),
  subheading: z.string().optional(),
  notes: z.string().optional(),
});


export type DocumentType = {
    id: number;
    company: Company
    type: string;
    document_number: string;
    order_number?: string;
    status?: string;
    issued_at: string; 
    due_at: string; 
    amount: number;
    currency: string;
    currency_rate?: number;
    discount?: number;
    discount_type?: string;
    category: Category
    contact: Category;
    title?: string;
    subheading?: string;
    notes?: string | null;
};

