import React from 'react'

const Permissions: React.FC = () => {
    return (
        <div className="bg-white mt-[4rem] dark:bg-gray-900">
            <div className="w-full mx-auto p-4">
                <table className="w-full rounded-md text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr className="space-x-4">
                            <th scope="col" className="px-6 py-3">Role Name</th>
                            <th scope="col" className="px-6 py-3">Module</th>
                            <th scope="col" className="px-6 py-3">Example</th>
                            <th scope="col" className="px-6 py-3 text-center">Permissions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 space-x-4">
                            <td className="px-6 py-3 font-medium text-gray-900 dark:text-white">View</td>
                            <td className="px-6 py-3 font-medium text-gray-900 dark:text-white">Module 1</td>
                            <td className="px-6 py-3 font-medium text-gray-900 dark:text-white">
                                <select className='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                    <option>Option 3</option>
                                </select>
                            </td>
                            <td className="px-6 py-3 flex justify-center space-x-2">
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Permissions
