import React from 'react';

const Contact: React.FC = () => {


    return (
        <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="px-4 mx-auto max-w-screen-sm text-center lg:px-6 mb-8 lg:mb-16">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">Contact Us</h2>
                    <p className="font-light text-gray-600 dark:text-gray-400 sm:text-xl max-w-4xl mx-auto">We use an agile approach to test assumptions and connect with the needs of your audience early and often.</p>
                </div>
                <div className="grid grid-cols-1 lg:gap-8 lg:grid-cols-3">
                    <div className="col-span-2 mb-8 lg:mb-0">
                        <form action="#" className="grid grid-cols-1 gap-8 mx-auto max-w-screen-md sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900">First Name</label>
                                <input type="text" id="first-name" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required />
                            </div>
                            <div>
                                <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900">Last Name</label>
                                <input type="text" id="last-name" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                                <input type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@flowbite.com" required />
                            </div>
                            <div>
                                <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                                <input type="number" id="phone-number" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="+12 345 6789" required />
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                                <textarea id="message" rows={6} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                                <br/>
                                <a className="mt-5 inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                                    Submit
                                    <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </form>
                        <p className="mt-4 text-sm text-gray-500 w-full">By submitting this form you agree to our <a href="#" className="text-primary-600 hover:underline dark:text-primary-500">terms and conditions</a> and our <a href="#" className="text-primary-600 hover:underline dark:text-primary-500">privacy policy</a> which explains how we may collect, use and disclose your personal information including to third parties.</p>
                    </div>
                    <div className="flex flex-col justify-center">
                        <div>
                            <div className="flex justify-center items-center mx-auto mb-4 w-10 h-10 rounded-l lg:h-16 lg:w-16">
                                <svg className="w-12 h-12 text-blue-600 lg:w-8 lg:h-8 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd"></path></svg>
                            </div>
                            <p className="flex justify-center mb-2 text-xl font-bold text-gray-900 dark:text-white">Company information:</p>
                            <p className=" flex justify-center text-gray-500 dark:text-gray-400">PGC SOFTWARE-PROZ</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mx-auto mb-4 w-10 h-10  rounded-l lg:h-16 lg:w-16">
                                <svg className="mt-8 w-12 h-12 text-blue-600 lg:w-8 lg:h-8 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
                            </div>
                            <p className="flex justify-center mb-2 text-xl font-bold text-gray-900 dark:text-white">Address:</p>
                            <p className="flex justify-center text-center text-gray-500 dark:text-gray-400">Isxyron 24, Kato Polemidia, 4151 Limassol Cyprus</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mx-auto mb-4 w-10 h-10  rounded-l lg:h-16 lg:w-16">
                                <svg className=" mt-8 w-12 h-12 text-blue-600 lg:w-8 lg:h-8 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
                            </div>
                            <p className="flex justify-center mb-2 text-xl font-bold text-gray-900 dark:text-white">Contact Us:</p>
                            <p className="flex justify-center text-center mb-3 text-gray-500 dark:text-gray-400">We would love to hear from you - please just fill out the form and we'll get back to you as soon as possible.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
