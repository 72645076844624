import { Account } from "@/types/Banking/Accounts";
import { Transaction } from "@/types/Banking/Transactions";
import { Country } from "@/types/Country";
import { Currency } from "@/types/Currency";
import { Document } from "@/types/Document/Document";
import { SelectOptionsType } from "@/types/FormFieldsType";
import { PermissionName } from "@/types/Permissions";
import { Category } from "@/types/Settings/Categories";
import { Contact } from "@/types/Settings/Contacts";
import { Department } from "@/types/Settings/Departments";

export const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export const formatObjectToSelectInput = (currencies: Currency[]): SelectOptionsType[] => {
    return currencies.map((curr) => {
        return {
            label: `${curr.currency} - ${curr.name}`,
            value: curr.currency
        }
    })
}


export const getCountryByCurrency = (currencies: Currency[] | null, country: string) => {
    if (!currencies) {
        return null
    }
    return currencies.find((curr) => curr.currency === country)?.country
};

export const getCurrency = (currencies: Currency[] | null) => {
    if (!currencies) {
        return []
    }
    return currencies.map((curr) => curr.currency)
}


export const formatCountryToSelectInput = (countries: Country[] | null): SelectOptionsType[] => {
    if (!countries) {
        return []
    }

    return countries.map((country) => {
        return {
            label: country.name,
            value: country.name
        }
    })
}

export const formatCurrencyToSelectInput = (currencies: Currency[] | null): SelectOptionsType[] => {
    if (!currencies) {
        return []
    }

    return currencies.map((currency) => {
        return {
            label: `${currency.currency} - ${currency.name}`,
            value: currency.currency
        }
    })
}

export const formatAccountToSelectInput = (accounts: Account[] | null) => {
    if (!accounts) {
        return []
    }

    return accounts.map((account) => {
        return {
            label: account.name,
            value: account.id.toString()
        }
    })
}


export const formatTransactionsToSelectInput = (transactions: Transaction[] | null) => {
    if (!transactions) {
        return []
    }

    return transactions.map((transaction) => {
        return {
            label: transaction.type,
            value: transaction.id.toString()
        }
    })
}

export const formatDepartmentsToSelectInput = (departments: Department[] | null) => {
    if (!departments) {
        return []
    }

    return departments.map((department) => {
        return {
            label: department.name,
            value: department.id.toString()
        }
    })
}

export const formatCategoryToSelectInput = (categories: Category[] | null) => {
    if (!categories) {
        return []
    }

    return categories.map((category) => {
        return {
            label: category.name,
            value: category.id.toString()
        }
    })
}

export const formatDocumentToSelectInput = (documents: Document[] | null) => {
    if (!documents) {
        return []
    }

    return documents.map((document) => {
        return {
            label: document.title ?? '',
            value: document.id.toString()
        }
    })
}

export const formatContactToSelectInput = (contacts: Contact[] | null) => {
    if (!contacts) {
        return []
    }

    return contacts.map((contact) => {
        return {
            label: contact.name,
            value: contact.id.toString()
        }
    })
}


export function formatDate(isoDateStr: string): string {
    const date = new Date(isoDateStr);
    
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    
    return `${year}/${month}/${day}`;
}



export const mapPermissionToNameAndIcon = (permissionName: PermissionName) => {
    switch (permissionName) {
        case 'read_reports':
            return {
                id: 1,
                name: 'Read Reports',
                icon: <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeWidth="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                    <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
            }
        case 'edit_accounting_and_payroll':
            return {
                id: 2,
                name: 'Edit Accounting and Payroll',
                icon: <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                </svg>
            }
        case 'read_invoicing':
            return {
                id: 3,
                name: 'Read Invoicing',
                icon: <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeWidth="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                    <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
            }
        case 'edit_invoicing':
            return {
                id: 4,
                name: 'Edit Invoicing',
                icon: <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                </svg>
            }
        default:
            return {
                id: 5,
                name: 'Unknown',
                icon: <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                </svg>
            }
    }
}