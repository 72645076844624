import { useSnackbar } from 'notistack';

export const useSnackBarAlert = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showSnackBar = (message: string, variant: 'success' | 'error' | 'warning' | 'info' = 'success', position: {
        vertical: 'top' | 'bottom',
        horizontal: 'left' | 'center' | 'right'
    }, duration: number = 2000) => enqueueSnackbar(message, { variant, autoHideDuration: duration, anchorOrigin: position, transitionDuration: { enter: 300, exit: 300 }, preventDuplicate: true })


    return {
        showSnackBar
    };
}