import React, { useState } from "react";
import LoadingPage from "../LoadingPage";
import FallbackPage from "../Fallback";
import GenericApi from "@/api/genericApi";
import { AccountQueryKey, CategoriesQueryKey, ContactsQueryKey, DocumentsQueryKey, TransactionsQueryKey } from "@/config/constants/QueryKeys"; // Changed TaxesQueryKey to TransactionsQueryKey
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { ApiError, ApiResponse } from "@/types/Api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdaptiveModal } from "@/components";
import { CustomModalPosition } from "@/types/Modal";
import CustomLogger from "@/utils/CustomLogger";
import FormField from "@/components/CustomForm/FormField";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ValidationErrors, FormError } from "@/types/ValidationError";
import { AxiosError, isAxiosError } from "axios";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { TransactionCreateRequest, TransactionCreateSchema, Transaction, TransactionType } from "@/types/Banking/Transactions";
import { generateTransactionsInputs } from "@/utils/generateInputs";
import TransactionsItem from "@/components/Transactions/TransactionsItem";
import { Account } from "@/types/Banking/Accounts";
import { Category } from "@/types/Settings/Categories";
import { Contact } from "@/types/Settings/Contacts";
import { Document } from "@/types/Document/Document";

const Transactions: React.FC = () => {
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TransactionCreateRequest>({ // Changed TaxCreateRequest to TransactionCreateRequest
    resolver: zodResolver(TransactionCreateSchema), // Changed TaxCreateSchema to TransactionCreateSchema
  });

  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const [transactionsModal, setTransactionsModal] = useState(false); // Changed to transactionsModal

  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [openFilter, setOpenFilter] = useState(false);

  const {
    data: transactions, // Changed to transactions
    isLoading: isLoadingTransactions, // Changed to isLoadingTransactions
    error,
  } = useQuery<ApiResponse<TransactionType[]>>({  // Changed to Transaction[]
    queryFn: async () =>
      GenericApi.get<TransactionType[]>(ApiRoutes.TRANSACTIONS), // Changed to Transaction[]
    queryKey: [TransactionsQueryKey], // Changed to TransactionsQueryKey
  });

  const {
    data: accounts, // Changed to accounts
    isLoading: isLoadingAccounts, // Changed to isLoadingAccounts
    error: accountsError,
  } = useQuery<ApiResponse<Account[]>>({
    queryFn: async () =>
      GenericApi.get<Account[]>(ApiRoutes.ACCOUNTS), // Changed to ACCOUNTS
    queryKey: [AccountQueryKey], // Changed to TransactionsQueryKey
  })

  const {
    data: documents,
    isLoading: isLoadingDocuments, 
    error: documentsError
  } = useQuery<ApiResponse<Document[]>>({
    queryFn: async () => GenericApi.get<Document[]>(ApiRoutes.DOCUMENTS), // Changed to DOCUMENTS
    queryKey: [DocumentsQueryKey],
  })

  const {
    data: categories,
    isLoading: isLoadingCategories,
    error: categoriesError,
  } = useQuery<ApiResponse<Category[]>>({
    queryFn: async () => GenericApi.get<Category[]>(ApiRoutes.CATEGORIES), // Changed to CATEGORIES
    queryKey: [CategoriesQueryKey], 
  })

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    error: contactsError,
  } = useQuery<ApiResponse<Contact[]>>({
    queryFn: async () => GenericApi.get<Contact[]>(ApiRoutes.CONTACTS), // Changed to CATEGORIES
    queryKey: [ContactsQueryKey], 
  })

    console.log(categories?.data , "DATA");  

  const transactionInputs = generateTransactionsInputs(
    accounts?.data ?? null,categories?.data ?? null, 
    contacts?.data ?? null, documents?.data ?? null,
); // Changed to transactionInputs

  const { selectedCompany } = useSelector((state: RootState) => state.company);

  const addTransactionMutation = useMutation<  // Changed to addTransactionMutation
    ApiResponse<Transaction>,  // Changed to Transaction
    ApiError<ValidationErrors>,
    TransactionCreateRequest // Changed to TransactionCreateRequest
  >({
    mutationFn: (data) =>
      GenericApi.post<Transaction>(ApiRoutes.TRANSACTIONS, data), // Changed to TRANSACTIONS
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      reset();
      setTransactionsModal(false);  // Changed to setTransactionsModal
      queryClient.invalidateQueries({ queryKey: [TransactionsQueryKey] }); // Changed to TransactionsQueryKey
      showSnackBar(
        "Transaction created",  // Changed text to "Transaction created"
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar(
        "Error creating transaction",  // Changed to "Error creating transaction"
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      console.log(error);
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      console.log(error);
      return;
    }

    console.log(error);
  };

  const onSubmit = (data: TransactionCreateRequest) => { // Changed to TransactionCreateRequest

    if(!selectedCompany) {
      showSnackBar(
        "Please select a company",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    const payload = {
      ...data,
      company_id: selectedCompany?.id,
      account_id: data.account,
      category_id: data.category,
      contact_id: data.contact,
      document_id: data.document,
    };

    CustomLogger.log(data);
    addTransactionMutation.mutate(payload);  // Changed to addTransactionMutation
  };

  const renderTransactionsBySearchValue = () => { // Changed to renderTransactionsBySearchValue
    if (searchValue) {
      const filteredTransactions = transactions?.data?.filter( // Changed to filteredTransactions
        (transaction) =>  // Changed to transaction
          transaction.type
      );
      return filteredTransactions?.map((transaction) => (    // Changed to transaction
        <TransactionsItem
          accounts={accounts?.data ?? null}
          documents={documents?.data ?? null}
          categories={categories?.data ?? null}
          contacts={contacts?.data ?? null}
          transaction={transaction}  // Changed to transaction
          key={transaction.id}  // Changed to transaction.id
        />
      ));
    }

    return transactions?.data?.map((transaction) => (      // Changed to transactions
      <TransactionsItem
        accounts={accounts?.data ?? null}
        documents={documents?.data ?? null}
        categories={categories?.data ?? null}
        contacts={contacts?.data ?? null}   // Changed to TransactionItem
        transaction={transaction}  // Changed to transaction
        key={transaction.id}  // Changed to transaction.id
      />
    ));
  };

  const toggleTransactionsModal = () => {
    setTransactionsModal(!transactionsModal);   // Changed to setTransactionsModal
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const onRetry = () => {
    window.location.reload();
  };

  if (isLoadingTransactions || isLoadingAccounts || isLoadingDocuments || isLoadingCategories || isLoadingContacts)    // Changed to isLoadingTransactions
    return (
      <div className="mt-[4rem]">
        <LoadingPage />
      </div>
    );

  const fetchError = error || contactsError || contacts?.error || transactions?.error || accountsError || accounts?.error || documentsError || documents?.error || categoriesError || categories?.error;   // Changed to transactions

  if (fetchError) {
    console.error(fetchError);
    return <FallbackPage onRetry={onRetry} />;
  }

  if (!transactions?.data) {
    console.error("Data is undefined");
    return <FallbackPage onRetry={onRetry} />;
  }

  return (
    <div className="bg-gray-50 mt-[4rem] dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
            <div className="flex justify-center items-center gap-4">
              <form className="flex items-center">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="2"
                        d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                    id="default-search"
                    className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search"
                    required
                  />
                </div>
              </form>
              <button
                onClick={toggleFilter}
                id="filterDropdownButton"
                data-dropdown-toggle="filterDropdown"
                className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd"
                  />
                </svg>
                Filter
                <svg
                  className="-mr-1 ml-1.5 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  />
                </svg>
                {openFilter ? (
                  <div className="z-[9999] absolute top-full w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                    <h6 className="mb-3 text-sm text-start font-medium text-gray-900 dark:text-white">
                      Date
                    </h6>
                    <ul
                      className="space-y-2 text-sm"
                      aria-labelledby="dropdownDefault"
                    >
                      <li className="flex items-center">
                        <input
                          id="apple"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor="apple"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                        >
                          Ascending
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="fitbit"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor="fitbit"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                        >
                          Descending
                        </label>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>

            <button
              onClick={toggleTransactionsModal}
              data-modal-toggle="createCompanyModal"
              type="button"
              className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              <svg
                className="h-3.5 w-3.5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                />
              </svg>
              Add Transaction   
                {/* modoficart  */}
            </button> 
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 space-x-4">
                <th scope="col" className="px-4 py-3 text-left">
                  Paid At
                </th>
                <th scope="col" className="px-4 py-3 text-left">
                  Number
                </th>
                <th scope="col" className="px-4 py-3">
                  Type
                </th>
                <th scope="col" className="px-4 py-3">
                  Category
                </th>
                <th scope="col" className="px-4 py-3">
                  Account
                </th>
                <th scope="col" className="px-4 py-3">
                  Amount
                </th>

                <th scope="col" className="px-4 py-3 text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{renderTransactionsBySearchValue()}</tbody>
          </table>
        </div>
      </div>

      <AdaptiveModal
        isOpen={transactionsModal}  //modificat 
        onClose={() => setTransactionsModal(false)}  //modificat 
        position={CustomModalPosition.Center}
        title={<p className="dark:text-white">Add Transaction</p>}  //modificat 
      >
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {transactionInputs.map((input) => (  //modificat 
            <FormField<TransactionCreateRequest>  //modificat 
              key={input.name}
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              required={input.required}
              valueAsNumber={input.valueAsNumber}
              inputStyle="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label={input.label}
              options={input.options}
              register={register}
              error={errors[input.name as keyof TransactionCreateRequest]}   //modificat
            /> 
          ))}
          <button
            type="submit"
            className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            {addTransactionMutation.isPending ? (
              <svg
                className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              ></svg>
            ) : (
              "Create Transaction"
            )}  
          </button>
        </form>
      </AdaptiveModal>
    </div>
  );
};

export default Transactions;
