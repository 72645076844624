import React from 'react'
import { Logo } from '@/components'

const LoadingPage: React.FC = () => {
  return (
    <div className="loader h-[calc(100dvh-6rem)] flex flex-col items-center justify-center">
      <Logo size={{ width: '200px', height: '70px' }} />
    </div>
  )
}

export default LoadingPage
