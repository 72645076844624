import { z, ZodSchema } from "zod";

export type Tax = {
    id: number;
    company: number;
    name: string;
    rate: string;
    type: string;
}

export type TaxCreateRequest = {
    name: string;
    rate: string;
    type: string;
}

export type TaxUpdate = {
    name: string;
    rate: string;
    type: string;
}

export const TaxCreateSchema: ZodSchema<TaxCreateRequest> = z.object({
    name: z.string().max(255),
    rate: z.string().regex(/^\d+(\.\d+)?$/),
    type: z.string().max(50),
});

export const TaxUpdateSchema: ZodSchema<TaxCreateRequest> = z.object({
    name: z.string().max(255),
    rate: z.string().regex(/^\d+(\.\d+)?$/),
    type: z.string().max(50),
});