import { z, ZodSchema } from "zod";
import { Company } from "../Company";
import { Department } from "../Settings/Departments";

export type Employee = {
    id: number;
    company: number; 
    name: string;
    email: string;
    birth_day: string;
    gender: string;
    phone: string;
    // department: number;
    department: string; 
    address: string;
    city: string;
    zip_code: string;
    state: string;
    country: string;
    amount: number;
    salary_type: string;
    currency: string;
    tax_number: string;
    bank_account_number: string;
    hired_at: string;
};

export type EmployeeCreateRequest = {
    name: string;
    email: string;
    birth_day: string;
    gender: string;
    phone?: string;
    // department: number | null; 
    department: string;
    address?: string;
    city?: string;
    zip_code?: string;
    state?: string;
    country: string;
    amount: number;
    salary_type: string;
    currency: string;
    tax_number?: string;
    bank_account_number?: string;
    hired_at: string;
}

export type EmployeeUpdateRequest = {
    name: string;
    email: string;
    birth_day: string;
    gender: string;
    phone?: string;
    address?: string;
    city?: string;
    zip_code?: string;
    state?: string;
    country: string;
    amount: number;
    salary_type: string;
    currency: string;
    tax_number?: string;
    bank_account_number?: string;
    hired_at: string;
}

export const EmployeeCreateSchema: ZodSchema<EmployeeCreateRequest> = z.object({
    name: z.string(),
    email: z.string().email(),
    birth_day:z.string(),
    gender: z.string(),
    phone: z.string().optional(),
    department: z.string(),
    address: z.string().optional(),
    city: z.string().optional(),
    zip_code: z.string().optional(),
    state: z.string().optional(),
    country: z.string(),
    amount: z.number(),
    salary_type: z.string(),
    currency: z.string(),
    tax_number: z.string().optional(),
    bank_account_number: z.string().optional(),
    hired_at: z.string(),
    
})

export const EmployeeUpdateSchema: ZodSchema<EmployeeUpdateRequest> = z.object({
    name: z.string(),
    email: z.string().email(),
    birth_day:z.string(),
    gender: z.string(),
    phone: z.string().optional(),
    department: z.string(),
    address: z.string().optional(),
    city: z.string().optional(),
    zip_code: z.string().optional(),
    state: z.string().optional(),
    country: z.string(),
    amount: z.number(),
    salary_type: z.string(),
    currency: z.string(),
    tax_number: z.string().optional(),
    bank_account_number: z.string().optional(),
    hired_at: z.string(),

})


export type EmployeeType = {
    id: number;
    company: Company;
    name: string;
    email: string;
    birth_day: string;
    gender: string;
    phone: string;
    department: Department;
    address: string;
    city: string;
    zip_code: string;
    state: string;
    country: string;
    amount: number;
    salary_type: string;
    currency: string;
    tax_number: string;
    bank_account_number: string;
    hired_at: string;  
};