import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-100">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <span className="block text-sm text-white text-center sm:text-center dark:text-gray-400">
          Copyright 2024 <a href="#" className="hover:underline">PandaTax,</a> All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
