import { z, ZodSchema } from "zod";

export type ExchangeRate = {
    id: number;
    base_currency: string;
    quote_currency: string;
    rate: string; 
    date: string; 
}

export type ExchangeRateCreateRequest = {
    base_currency: string;
    quote_currency: string;
    rate: string; 
    date: string; 
}

export type ExchangeRateUpdate = {
    base_currency: string;
    quote_currency: string;
    rate: string; 
    date: string; 
}

export const ExchangeRateCreateSchema: ZodSchema<ExchangeRateCreateRequest> = z.object({
    base_currency: z.string().length(3),
    quote_currency: z.string().length(3),
    rate: z.string().regex(/^\d+(\.\d+)?$/), 
    date: z.string().date().regex(/^\d{4}-\d{2}-\d{2}$/), 
});

export const ExchangeRateUpdateSchema: ZodSchema<ExchangeRateCreateRequest> = z.object({
    base_currency: z.string().length(3),
    quote_currency: z.string().length(3),
    rate: z.string().regex(/^\d+(\.\d+)?$/), 
    date: z.string().date().regex(/^\d{4}-\d{2}-\d{2}$/), 
});