import React from 'react'
import 'flowbite'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorBoundary from '@/utils/ErrorBoundary.tsx'
import FallbackPage from './pages/Fallback.tsx'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import store from '@/store'
import { initializeSentry } from '@/utils/sentry.tsx'
import { Mode } from '@/config/constants/Constants.tsx'

const queryClient = new QueryClient()

const onRetry = () => {
  window.location.reload()
}

const isProduction = import.meta.env.VITE_MODE === Mode.PRODUCTION

initializeSentry(isProduction)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary fallback={<FallbackPage onRetry={onRetry} />}>
          <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <App />
            </QueryClientProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)
