import React, { Fragment } from 'react';

const About: React.FC = () => {
    return (
        <Fragment>
            <section className="bg-white">
                <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">We didn’t reinvent the wheel. We’re just making it better.</h2>
                        <p className="mb-4">We’ve used countless different finance apps that handled everything in their own peculiar way. Most of them were clunky, slow, and difficult to use, and the ones that were simple had one drawback.</p>
                        <p className="mb-4">They couldn’t connect with any of our systems, or other applications we were using in tandem with it. That’s why we created PandaTax.</p>
                        <p>We saw that there was a deep need for an all-in-one Accounting, Payroll, and Invoicing solution that is fast, easy-to-use and connects flawlessly with everything within your business.</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-8">
                        <img className="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1" />
                        <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2" />
                    </div>
                </div>
            </section>

            <section className="bg-blue-700">
                <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-white sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">We aim to be the Financial Backbone of 10,000 businesses.</h2>
                        <p className="mb-4">It’s going to take a long time, and that’s why we’re in it for the long haul. That’s why we are rolling out updates every week to make everything stronger, faster and smoother for our users</p>
                        <div className="flex flex-row items-center space-x-5">
                            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-gray-600">
                                Book Your Demo
                            </a>
                        </div>
                    </div>
                    <div className="mt-4 font-light text-white sm:text-lg lg:mt-0 dark:text-gray-400">
                        <p className="mb-4">We want to be right behind every ‘technological’ breakthrough, and find a way to integrate it in our current systems. This way, our users are constantly on the cutting edge of Accounting, Payroll and Invoicing.</p>
                        <p>After all, your business deserves the best. Should you choose to work with us, we will work tirelessly to make sure you not only never regret your decision - you also become a part of the wave of change we will be bringing in the industry.</p>
                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-16 lg:px-6">
                    <div className="col-span-2 mb-8">
                        <p className="text-lg font-medium text-blue-600 dark:text-blue-500">Trusted Worldwide</p>
                        <h2 className="mt-3 mb-4 text-3xl  font-extrabold tracking-tight text-gray-900 md:text-4xl dark:text-white">Our system does all the heavy lifting</h2>
                        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Thousands of automations and processes happen every hour, so all you have to do is fill out your forms, balance sheets, invoices; and any other process you think of.</p>
                        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 pt-5">We want you to have <u>zero</u> Headaches when using PandaTax. </p>
                        <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                            {/* Placeholder for additional content */}
                        </div>
                    </div>
                    <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                        <div>
                            <svg className="mb-2 w-10 h-10 text-blue-600 md:w-12 md:h-12 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z" clipRule="evenodd"></path></svg>
                            <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">99.9% Uptime</h3>
                            <p className="font-light text-gray-500 dark:text-gray-400">Anytime you want to use PandaTax, it will be up and running at maximum speed</p>
                        </div>
                        <div>
                            <svg className="mb-2 w-10 h-10 text-blue-600 md:w-12 md:h-12 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd"></path></svg>
                            <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">All currencies supported</h3>
                            <p className="font-light text-gray-500 dark:text-gray-400">Any currency your business needs, we have in our records updated daily from the European Central Bank</p>
                        </div>
                        <div>
                            <svg className="mb-2 w-10 h-10 text-blue-600 md:w-12 md:h-12 dark:text-primary-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4"/>
                            </svg>
                            <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">Weekly Updates</h3>
                            <p className="font-light text-gray-500 dark:text-gray-400">Stress tests, bug fixes and additional features - we leave nothing to chance</p>
                        </div>
                        <div>
                            <svg className="mb-2 w-10 h-10 text-blue-600 md:w-12 md:h-12 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z" clipRule="evenodd"></path><path fillRule="evenodd" d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z" clipRule="evenodd"></path><path fillRule="evenodd" d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">100% Secure</h3>
                            <p className="font-light text-gray-500 dark:text-gray-400">Your data is sensitive and extremely important. Our system is monitored 24/7 and runs the highest security storage.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default About;
