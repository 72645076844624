import GenericApi from '@/api/genericApi';
import FormField from '@/components/CustomForm/FormField';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { RoutesPathList } from '@/config/routes/Routes';
import { ApiResponse, ApiError } from '@/types/Api';
import { AuthResponse, RegisterRequest, RegisterRequestSchema } from '@/types/Auth';
import { FormError, ValidationErrors } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { formatError } from '@/utils/formatError';
import { generateRegisterInputs } from '@/utils/generateInputs';
import { wait } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Logo } from '@/components';
import { CurrencyQueryKey } from '@/config/constants/QueryKeys';
import { Currency } from '@/types/Currency';
import { LoadingPage, FallbackPage } from '..';

const RegisterPage: React.FC = () => {
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<RegisterRequest>({
        resolver: zodResolver(RegisterRequestSchema),
    });

    const { data: currencies, isLoading: isLoadingCurrencies, error } = useQuery<ApiResponse<Currency[]>>(
        {
            queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
            queryKey: [CurrencyQueryKey],
        }
    );

    const registerMutation = useMutation<ApiResponse<AuthResponse>, ApiError<ValidationErrors>, RegisterRequest>(
        {
            mutationFn: (data) => GenericApi.post<AuthResponse>(ApiRoutes.REGISTER, {
                ...data,
            }),
            onSuccess: async ({ }) => {
                console.log("registerMutation -> data", "OK")
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            setError('root', {
                type: 'manual',
                message: error.response?.data?.detail ?? 'An error occurred while registering'
            })
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            setError('root', {
                type: 'manual',
                message: formatError(error, 'An error occurred while registering')
            })
            return
        }

        //* Set error message from server
        setError('root', {
            type: 'manual',
            message: error?.message ?? 'An error occurred while registering'
        })
    }

    const getFieldError = (error: any): FieldError | undefined => {
        if (error && "type" in error) {
          return error as FieldError;
        } else if (Array.isArray(error)) {
          return error.find((err) => err !== undefined);
        }
        return undefined;
      };

    const onSubmit = async (data: RegisterRequest) => {
        CustomLogger.log(data)
        await registerMutation.mutateAsync(data)
        await wait(10000)
        navigate(RoutesPathList.Login, { replace: true })
    }

    const registerInputs = generateRegisterInputs(currencies?.data ?? null);

    console.log(registerInputs)

    const onRetry = () => {
        window.location.reload();
    }

    if (isLoadingCurrencies) return <div>
        <LoadingPage />
    </div>;

    const fetchError = error || currencies?.error;

    if (fetchError) {
        console.error(fetchError);
        return <FallbackPage onRetry={onRetry} />;
    }

    if (!currencies?.data) {
        console.error("Data is undefined");
        return <FallbackPage onRetry={onRetry} />;
    }

    return (
        <div className="bg-gray-50 dark:bg-gray-900 min-h-[100dvh]">
            <div className="max-w-screen-xl px-4 py-8 mx-auto">
                <div onClick={() => navigate(RoutesPathList.Landing)} className="cursor-pointer mb-6 text-center">
                    <Logo size={{
                        width: "150px",
                        height: "70px"
                    }} />
                </div>
                <div className="w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0 lg:col-span-7 xl:col-span-6">
                    <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl dark:text-white">
                            Create your Account
                        </h1>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-300">
                            Create your account in seconds. Already have an account? <a href="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>.
                        </p>
                        {
                            registerMutation.isError ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">
                                    {
                                        errors.root?.message
                                    }
                                </span>
                            </div> : null
                        }
                        {
                            registerMutation.isSuccess ?
                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                    <span className="block sm:inline">
                                        Check your email to verify your account
                                    </span>
                                </div> : null
                        }
                        <form className="space-y-4 lg:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                            {
                                registerInputs.map((input, index) => (
                                    <div key={input.name}>
                                        <FormField<RegisterRequest>
                                            key={index}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            name={input.name}
                                            required={input.required}
                                            valueAsNumber={input.valueAsNumber}
                                            label={input.label}
                                            error={getFieldError(errors[input.name as keyof RegisterRequest])}
                                            register={register}
                                            options={input?.options ?? []}
                                            multiple={input?.multiple ?? false}
                                            inputStyle={` ${input?.multiple ? 'h-[20rem]' : ''} bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                                            />
                                    </div>
                                ))
                            }
                            <button type="submit" className="w-full flex justify-center items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                {
                                    registerMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : 'Create Account'
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
