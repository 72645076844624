import { User } from "@/types/User";
import { authStorageToken, authStorageUser, themeStorageKey } from "../config/constants/StorageKeys";
import { AuthResponse, AuthToken } from "../types/Auth";
import { CurrentTheme, Theme } from "@/types/Theme";


const setToken = (token: AuthToken): void => {
    localStorage.setItem(authStorageToken, token)
}

const getToken = (): AuthToken | null => {
    const token: AuthToken | null = getData<AuthResponse>(authStorageUser)?.access ?? null;
    return token
}

const saveData = (key: string, data: unknown) => localStorage.setItem(key, JSON.stringify(data))

function getData<T>(key: string): T | null {
    const data = localStorage.getItem(key)
    return data ? JSON.parse(data) : null
}

const removeData = (key: string) => localStorage.removeItem(key);
const resetLocalStorage = () => localStorage.clear()

const clearToken = () => localStorage.removeItem(authStorageToken)

const getUserToken = (): AuthToken | null => {
    return getData<AuthResponse>(authStorageUser)?.access ?? null
}

const getUser = (): User | null => {
    return getData<AuthResponse>(authStorageUser)?.user ?? null
}

const updateUserData = (user: User | null) => {
    const data = getData<AuthResponse>(authStorageUser);
    if (data && user) {
        saveData(authStorageUser, { ...data, user });
        return true
    }
    return false
}

const getCurrentTheme = (): CurrentTheme => {
    return getData<CurrentTheme>(themeStorageKey) ?? Theme.LIGHT
}

export {
    setToken,
    getToken,
    clearToken,
    saveData,
    getCurrentTheme,
    getData,
    getUser,
    removeData,
    getUserToken,
    updateUserData,
    resetLocalStorage,
}