import React, { Fragment, useState } from 'react';
import GenericApi from '@/api/genericApi';
import { OrganizationQueryKey } from '@/config/constants/QueryKeys';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { ApiError, ApiResponse } from '@/types/Api';
import { Organization, OrganizationForm, OrganizationFormSchema } from '@/types/Organization';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FallbackPage, LoadingPage } from '..';
import OrganizationItem from './organization';
import { FormError, ValidationErrors } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { useSnackBarAlert } from '@/hooks/useSnackbar';
import { AxiosError, isAxiosError } from 'axios';
import { getUser } from '@/utils/localStorage';
import FormField from '@/components/CustomForm/FormField';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FormFieldType } from '@/types/FormFieldsType';
import { AdaptiveModal } from '@/components';
import { CustomModalPosition } from '@/types/Modal';

const Organizations: React.FC = () => {

    const [organizationModalOpen, setOrganizationModalOpen] = useState<boolean>(false);
    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const user = getUser()
    const [searchValue, setSearchValue] = useState<string>('')
    const [openFilter, setOpenFilter] = useState<boolean>(false)


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<OrganizationForm>({
        resolver: zodResolver(OrganizationFormSchema),
    });

    const { data: organizations, isLoading, error } = useQuery<ApiResponse<Organization[]>>(
        {
            queryFn: async () => GenericApi.get<Organization[]>(ApiRoutes.ORGANIZATIONS),
            queryKey: [OrganizationQueryKey],
        }
    );


    console.log(organizations)

    const toggleOrganizationModal = () => {
        setOrganizationModalOpen(!organizationModalOpen);
    }

    const addOrganizationMutation = useMutation<ApiResponse<Organization>, ApiError<ValidationErrors>, OrganizationForm>(
        {
            mutationFn: (data) => GenericApi.post<Organization>(ApiRoutes.ORGANIZATIONS, {
                ...data
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setOrganizationModalOpen(false)
                reset()
                queryClient.invalidateQueries({ queryKey: [OrganizationQueryKey] })
                showSnackBar("Organization created", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error creating organization", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )


    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }


    const renderOrganizationBySearchValue = () => {
        if (searchValue) {
            const filteredOrganizations = organizations?.data?.filter((organization) => organization.name.toLowerCase().includes(searchValue.toLowerCase()))
            return filteredOrganizations?.map((organization) => (
                <OrganizationItem organization={organization} key={organization.id} />
            ));
        }

        return organizations?.data?.map((organization) => (
            <OrganizationItem organization={organization} key={organization.id} />
        ));
    }

    const onRetry = () => {
        window.location.reload();
    }

    const handleAddOrganization = (data: OrganizationForm) => {
        if (!user?.id) {
            showSnackBar("Something went wrong", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            return
        }

        addOrganizationMutation.mutate({
            name: data.name,
            owner_id: user?.id
        })
    }

    const onSubmit = (data: OrganizationForm) => {
        console.log(data)
        handleAddOrganization(data)
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter)
    }

    //! TODO FIX MARGIN TOP
    if (isLoading) return <div className='mt-[4rem]'>
        <LoadingPage />
    </div>;

    if (error || organizations?.error) return <FallbackPage onRetry={onRetry} />;

    return (
        <Fragment>
            <div className="dashboard w-full mt-[4rem]">
                <section className="bg-gray-50 dark:bg-gray-900">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
                                <div className="flex justify-center items-center gap-4">
                                    <form className="flex items-center">
                                        <label htmlFor="search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="relative w-full">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <svg
                                                    className="w-4 h-4 text-gray-800 dark:text-white"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-width="2"
                                                        d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                                                    />
                                                </svg>
                                            </div>
                                            <input onChange={(e) => setSearchValue(e.target.value)} type="search" id="default-search" className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                                        </div>
                                    </form>
                                    <button onClick={toggleFilter} id="filterDropdownButton" data-dropdown-toggle="filterDropdown" className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
                                        </svg>
                                        Filter
                                        <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                        </svg>
                                        {
                                            openFilter ? <div className="z-50 absolute top-full w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                                                <h6 className="mb-3 text-sm text-start font-medium text-gray-900 dark:text-white">
                                                    Date
                                                </h6>
                                                <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">
                                                    <li className="flex items-center">
                                                        <input id="apple" type="checkbox" value=""
                                                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                        <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                            Ascending
                                                        </label>
                                                    </li>
                                                    <li className="flex items-center">
                                                        <input id="fitbit" type="checkbox" value=""
                                                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                        <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                            Descending
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div> : null
                                        }
                                    </button>


                                </div>
                                <button onClick={toggleOrganizationModal} className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                                    <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path clipRule="evenodd" fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                                    </svg>
                                    Create Organization
                                </button>
                            </div>
                        </div>
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-4 py-3">Organization name</th>
                                        <th scope="col" className="px-4 py-3">Created At</th>
                                        <th scope="col" className="pr-7 py-3 text-right">Select</th>
                                        <th scope="col" className="px-4 py-3 text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{renderOrganizationBySearchValue()}</tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>


            <AdaptiveModal
                title={<p className='dark:text-white'>Create Organization</p>}
                isOpen={organizationModalOpen}
                onClose={toggleOrganizationModal}
                position={CustomModalPosition.Center}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    <FormField<OrganizationForm>
                        label="Organization Name"
                        name="name"
                        placeholder="Enter your organization name"
                        required
                        register={register}
                        error={errors.name}
                        type={FormFieldType.TEXT}
                        valueAsNumber={false}
                        inputStyle='bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                    />
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            addOrganizationMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Create Organization"
                        }
                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    );
};

export default Organizations;
