import { ZodSchema, z } from "zod";

export type Company = {
    id: number;
    name: string;
    email: string;
    country: string;
    currency: string;
    address: string;
    phone: string;
    tax_number: string;
    city: string;
    state: string;
    zip_code: string;
}

export type CompanyUpdateRequest = {
    name: string;
    email: string;
    country: string;
    currency: string;
    address: string;
    phone: string;
    tax_number: string;
    city: string;
    state: string;
    zip_code: string;
}

export type CompanySliceState = {
    selectedCompany: Company | null;
}

export type CompanyCreateRequest = {
    name: string;
    email: string;
    country: string;
    currency: string;
    address: string;
    phone: string;
    tax_number: string;
    city: string;
    state: string;
    zip_code: string;
}

export type CompanyUser = {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    organization_id: number;
    is_active: boolean;
}

export const CompanyCreateSchema: ZodSchema<CompanyCreateRequest> = z.object({
    name: z.string().min(3, {
        message: 'Name must be at least 3 characters long'
    }).max(255, {
        message: 'Name must be at most 255 characters long'
    }),
    email: z.string().email(),
    country: z.string(),
    address: z.string().min(1, {
        message: 'Adress must be at least 1 characters long'
    }).max(255, {
        message: 'Adress must be at most 255 characters long'
    }),
    currency: z.string().min(1, {
        message: 'Currency must be at least 1 characters long'
    }).max(255, {
        message: 'Currency must be at most 255 characters long'
    }),
    phone: z.string().min(1, {
        message: 'Phone Number must be at least 1 characters long'
    }).max(20, {
        message: 'Phone Number must be at most 20 characters long'
    }),
    tax_number: z.string().min(1, {
        message: 'Tax Number must be at least 1 characters long'
    }).max(255, {
        message: 'Tax Number must be at most 255 characters long'
    }),
    city: z.string().min(1, {
        message: 'City must be at least 1 characters long'
    }).max(255, {
        message: 'City must be at most 255 characters long'
    }),
    state: z.string().min(1, {
        message: 'State must be at least 1 characters long'
    }).max(255, {
        message: 'State must be at most 255 characters long'
    }),
    zip_code: z.string().min(1, {
        message: 'Zip Code must be at least 1 characters long'
    }).max(20, {
        message: 'Zip Code must be at most 20 characters long'

    }),
})


export const CompanyUpdateSchema: ZodSchema<CompanyUpdateRequest> = z.object({
    name: z.string().min(3, {
        message: 'Name must be at least 3 characters long'
    }).max(255, {
        message: 'Name must be at most 255 characters long'
    }),
    email: z.string().email(),
    country: z.string(),
    address: z.string().min(1, {
        message: 'Adress must be at least 1 characters long'
    }).max(255, {
        message: 'Adress must be at most 255 characters long'
    }),
    currency: z.string().min(1, {
        message: 'Currency must be at least 1 characters long'
    }).max(255, {
        message: 'Currency must be at most 255 characters long'
    }),
    phone: z.string().min(1, {
        message: 'Phone Number must be at least 1 characters long'
    }).max(20, {
        message: 'Phone Number must be at most 20 characters long'
    }),
    tax_number: z.string().min(1, {
        message: 'Tax Number must be at least 1 characters long'
    }).max(255, {
        message: 'Tax Number must be at most 255 characters long'
    }),
    city: z.string().min(1, {
        message: 'City must be at least 1 characters long'
    }).max(255, {
        message: 'City must be at most 255 characters long'
    }),
    state: z.string().min(1, {
        message: 'State must be at least 1 characters long'
    }).max(255, {
        message: 'State must be at most 255 characters long'
    }),
    zip_code: z.string().min(1, {
        message: 'Zip Code must be at least 1 characters long'
    }).max(20, {
        message: 'Zip Code must be at most 20 characters long'

    }),
})

