import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesPathList } from '@/config/routes/Routes';
import { Logo } from '@/components';

const ActivationSuccess: React.FC = () => {
  return (
    <div className="flex flex-col dark:bg-gray-800 items-center justify-center h-screen text-center bg-gray-100 p-5">
      <Logo size={
        {
          width: "200px",
          height: "70px"
        }} className="mb-5" />
      <h1 className="text-2xl dark:text-white font-bold mb-3">Activation Successful</h1>
      <p className="text-lg dark:text-gray-100 text-gray-700">
        Your account has been successfully activated. You can now log in and start using our services.
      </p>
      <Link to={RoutesPathList.Login} className="text-primary-600 hover:underline mt-5">Login</Link>
    </div>
  );
}

export default ActivationSuccess;
