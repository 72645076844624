import React, { Fragment, useState } from 'react'
import GenericApi from '@/api/genericApi'
import { DepartmentsQueryKey } from '@/config/constants/QueryKeys'
import { ApiRoutes } from '@/config/routes/ApiRoutes'
import { ApiResponse, ApiError } from '@/types/Api'
import { FormError, ValidationErrors } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import { AxiosError, isAxiosError } from 'axios'
import { AdaptiveModal, LoadingSpinner } from '..'
import { CustomModalPosition } from '@/types/Modal'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import FormField from '../CustomForm/FormField'
import { generateDepartmentsUpdateInputs } from '@/utils/generateInputs'
import { Department, DepartmentUpdate, DepartmentUpdateSchema } from '@/types/Settings/Departments'

export interface DepartmentsItemProps {  //modificat ***
    departments: Department  //modificat ***
}


const DepartmentsItem: React.FC<DepartmentsItemProps> = ({ departments }) => {  //modificat ***

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DepartmentUpdate>({ //modificat ***
        resolver: zodResolver(DepartmentUpdateSchema), //modificat ***
    });

    console.log({
        errors
    })

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)

    const deleteDepartmentsMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>( //modificat ***
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`${ApiRoutes.DEPARTMENTS}/${data.id}`), //modificat ***
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [DepartmentsQueryKey] }) //modificat ***
                showSnackBar("Department deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting Department", 'error', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleDelete = (id: number) => {
        deleteDepartmentsMutation.mutate({ id }) //modificat ***
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const updateDepartmentsMutation = useMutation<ApiResponse<Department>, ApiError<ValidationErrors>, DepartmentUpdate>( //modificat ***
        {
            mutationFn: (data) => GenericApi.put<Department>(`${ApiRoutes.DEPARTMENTS}/${departments.id}`, data), //modificat ***
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setEditModalOpen(false)
                queryClient.invalidateQueries({ queryKey: [DepartmentsQueryKey] }) //modificat ***
                showSnackBar("Department updated", 'success', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error updating Department", 'error', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            }
        }
    )

    const updateDepartmentsInputs = generateDepartmentsUpdateInputs(departments) //modificat ***

    const onSubmit = (data: DepartmentUpdate) => { //modificat ***
        updateDepartmentsMutation.mutate(data) //modificat ***
    }

    return (
        <Fragment>
            <tr
                className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
                
                <td className="px-4 py-3 text-left">{departments.name}</td>
                <td className="px-4 py-3 text-center">{departments.description}</td>
                <td className="px-4 py-3 text-right">
                    <button
                        onClick={toggleEditModal}
                        className="font-medium text-green-400 dark:text-green-400 hover:underline"
                    >
                        Edit
                    </button>
                    <span className="mx-2">|</span>
                    <button
                        onClick={toggleDeleteModal}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                        Delete
                    </button>

                </td>
            </tr>


            <AdaptiveModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Department</h2>}
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button
                            onClick={() => handleDelete(departments.id)}
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
                        >
                            {
                                deleteDepartmentsMutation.isPending ? <LoadingSpinner variant='danger' /> : 'Delete' //modificat ***
                            }
                        </button>
                        <button
                            onClick={toggleDeleteModal}
                            className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            >
                <p className="text-gray-700 dark:text-gray-300">
                    Are you sure you want to delete this department?
                </p>
                {/* modifica *** */}
            </AdaptiveModal>


            <AdaptiveModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Edit Department</h2>}
                position={CustomModalPosition.Center}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        updateDepartmentsInputs.map(input => (
                            <FormField<DepartmentUpdate> //modificat ***
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                label={input.label}
                                defaultValue={input?.defaultValue ?? ''}
                                register={register}
                                error={errors[input.name as keyof DepartmentUpdate]} //modificat ***
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            updateDepartmentsMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Edit Department"
                         //modificat ***
                        }

                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    )
}

export default DepartmentsItem;
