class CustomLogger {
  private static logs: { type: string; timestamp: string; message: unknown }[] = [];

  private static getShortTimestamp(): string {
    const now = new Date();
    return now.toLocaleTimeString('en-US', { hour12: false });
  }

  private static logToFile(type: string, message: unknown): void {
    const logEntry = {
      type,
      timestamp: this.getShortTimestamp(),
      message,
    };
    this.logs.push(logEntry);
  }

  static log(message: unknown, line = 0): void {
    const timestamp = this.getShortTimestamp();
    if (typeof message === 'object' && message !== null) {
      console.log(`${timestamp} ${line} - INFO:`, message);
    } else {
      console.log(`${timestamp} ${line} - INFO: ${String(message)}`);
    }
    this.logToFile('INFO', message);
  }

  static error(message: unknown): void {
    const timestamp = this.getShortTimestamp();
    if (typeof message === 'object' && message !== null) {
      console.error(`${timestamp} - ERROR:`, message);
    } else {
      console.error(`${timestamp} - ERROR: ${String(message)}`);
    }
    this.logToFile('ERROR', message);
  }

  static getLogs(): { type: string; timestamp: string; message: unknown }[] {
    return this.logs;
  }
}

export default CustomLogger;
