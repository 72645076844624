import { CreditCard } from '@/components'
import React from 'react'

const Billing: React.FC = () => {
    return (
        <div className="bg-white mt-[4rem] dark:bg-gray-900">
            <div className="mx-4 max-w-screen-xl sm:mx-8 xl:mx-auto">
                <div className="w-full pt-3 pb-10 sm:grid-cols-10">
                    <div className="col-span-8 rounded-x sm:px-8 sm:shadow rounded-lg pb-6 dark:bg-gray-800 dark:text-gray-200">
                        <div className="pt-4">
                            <h1 className="py-2 text-2xl font-semibold">Billing settings</h1>
                            <p className="text-slate-600 dark:text-gray-400">
                                Manage your billing details, payment methods, and billing history.
                            </p>
                        </div>
                        <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700" />

                        <div className="mb-10 grid gap-y-8 lg:grid-cols-2 lg:gap-y-0">
                            <div className="space-y-8">
                                <div className="">
                                    <div className="flex">
                                        <p className="font-medium mb-1">Billing Period</p>
                                        <button className="ml-auto inline-flex text-sm font-semibold text-blue-600 underline decoration-2 dark:text-blue-500">Change</button>
                                    </div>
                                    <div className="flex items-center rounded-md border border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 py-3 shadow">
                                        <p className="ml-4 w-56">
                                            <strong className="block text-lg font-medium">MONTHLY</strong>
                                            <span className="text-xs text-gray-400 dark:text-gray-500"> Next Renewal: 4 Jan 2022 </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="flex">
                                        <p className="font-medium mb-1">Payment Method</p>
                                        <button className="ml-auto inline-flex text-sm font-semibold text-blue-600 underline decoration-2 dark:text-blue-500">Change</button>
                                    </div>
                                    <div className="flex items-center rounded-md border border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 py-3 shadow">
                                        <CreditCard />
                                        <p className="ml-4 w-56">
                                            <strong className="block text-lg font-medium">**** **** **** 453 </strong>
                                            <strong className="block text-lg font-medium">YOUR NAME</strong>
                                            <span className="text-xs text-gray-400 dark:text-gray-500"> Expires on: Dec 2024 </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="grid gap-y-6 gap-x-3 sm:grid-cols-2 lg:px-8">
                                <label className="block" htmlFor="name">
                                    <p className="text-sm">Name</p>
                                    <input className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1" type="text" value="Shakir Ali" />
                                </label>
                                <label className="block" htmlFor="email">
                                    <p className="text-sm">Email Address</p>
                                    <input className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1" type="text" value="shakir.ali@corpora.de" />
                                </label>
                                <label className="block sm:col-span-2" htmlFor="billing-address">
                                    <p className="text-sm">Billing Address</p>
                                    <input className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1" type="text" value="82844 Boyle Extension Suite 541 - Covington, HI / 28013" />
                                </label>
                                <label className="block" htmlFor="vat">
                                    <p className="text-sm">VAT #</p>
                                    <input className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1" type="text" value="6346322" />
                                </label>
                                <label className="block" htmlFor="country">
                                    <p className="text-sm">Country</p>
                                    <input className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1" type="text" value="Germany" />
                                </label>
                            </div>
                        </div>
                        <h1 className="py-2 text-2xl font-semibold">Billing history</h1>
                        <p className="text-slate-600 dark:text-gray-400">
                            View and download your invoices.
                        </p>
                        <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700" />
                        <div className="amx-auto mb-10 overflow-hidden rounded-lg border bg-white dark:bg-gray-800 dark:border-gray-700">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Date
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Invoice
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            PDF
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-4 py-3 text-center">23 Nov 2021</td>
                                        <td className="px-4 py-3 text-center">X-543242</td>
                                        <td className="px-4 py-3 text-center">$99.00</td>
                                        <td className="px-4 py-3 text-center">
                                            <button className="text-sm text-blue-600 dark:text-blue-500 underline">PDF</button>
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-4 py-3 text-center">23 Nov 2021</td>
                                        <td className="px-4 py-3 text-center">X-543242</td>
                                        <td className="px-4 py-3 text-center">$99.00</td>
                                        <td className="px-4 py-3 text-center">
                                            <button className="text-sm text-blue-600 dark:text-blue-500 underline">PDF</button>
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-4 py-3 text-center">23 Nov 2021</td>
                                        <td className="px-4 py-3 text-center">X-543242</td>
                                        <td className="px-4 py-3 text-center">$99.00</td>
                                        <td className="px-4 py-3 text-center">
                                            <button className="text-sm text-blue-600 dark:text-blue-500 underline">PDF</button>
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-4 py-3 text-center">23 Nov 2021</td>
                                        <td className="px-4 py-3 text-center">X-543242</td>
                                        <td className="px-4 py-3 text-center">$99.00</td>
                                        <td className="px-4 py-3 text-center">
                                            <button className="text-sm text-blue-600 dark:text-blue-500 underline">PDF</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Billing
