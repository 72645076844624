import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, ApiResponse } from "@/types/Api";
import {
  CurrencyQueryKey,
  DoubleEntryAccountQueryKey,
  JournalEntryQueryKey,
} from "@/config/constants/QueryKeys";
import GenericApi from "@/api/genericApi";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { DoubleEntryAccount } from "@/types/DoubleEntry";
import { FallbackPage, LoadingPage } from "@/pages";
import { DoubleEntryJournal, DoubleEntryJournalCreateRequest } from "@/types/Journal";
import { FormError, ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { AxiosError, isAxiosError } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { Currency } from "@/types/Currency";

type Pair = {
  debit_account: string;
  credit_account: string;
  debit: number;
  credit: number;
};

const JournalItem: React.FC = () => {
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const selectedCompany = useSelector((state: RootState) => state.company.selectedCompany);
  const selectedPage = useSelector((state:RootState) => state?.page?.id)
  const [pairs, setPairs] = useState<Pair[]>([
    { debit_account: "", credit_account: "", debit: 0, credit: 0 },
  ]);
  const [journalNumber, setJournalNumber] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");


  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    error: errorAccounts,
  } = useQuery<ApiResponse<DoubleEntryAccount[]>>({
    queryFn: async () => GenericApi.get<DoubleEntryAccount[]>(ApiRoutes.DOUBLE_ENTRY),
    queryKey: [DoubleEntryAccountQueryKey],
  });

  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    error: errorCurrencies,
  } = useQuery<ApiResponse<Currency[]>>({
    queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
    queryKey: [CurrencyQueryKey],
  });


  const {
    data: journals,
    isLoading: isLoadingJournals,
    error: errorJournals,
  } = useQuery<ApiResponse<DoubleEntryJournal[]>>({
    queryFn: async () => GenericApi.getById<DoubleEntryJournal[]>(ApiRoutes.JOURNALS,selectedPage!),
    queryKey: [JournalEntryQueryKey, selectedPage],
  });
  
  console.log(journals, "journals");

  const addJournalMutation = useMutation<
    ApiResponse<DoubleEntryJournal>,
    ApiError<ValidationErrors>,
    DoubleEntryJournalCreateRequest
  >({
    mutationFn: (data) => GenericApi.post<DoubleEntryJournal>(ApiRoutes.JOURNALS, data),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [JournalEntryQueryKey] });
      showSnackBar("Journal created", "success", { vertical: "top", horizontal: "right" }, 3000);
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar("Error creating Journal Entry", "error", { vertical: "top", horizontal: "right" }, 3000);
    },
  });

  const renderJournalEntries = () => {
    return journals?.data?.map((journal) => (
      <tr key={journal.id}>
        <td className="px-6 py-3 text-center">{journal.journal_number}</td>
        <td className="px-6 py-3 text-center">{journal.date}</td>
        <td className="px-6 py-3 text-center">{journal.dr?.name}</td>
        <td className="px-6 py-3 text-center">{journal.cr?.name}</td>
        <td className="px-6 py-3 text-center">{journal.document_number}</td>
        <td className="px-6 py-3 text-center">{journal.description}</td>
        <td className="px-6 py-3 text-center">{journal.debit}</td>
        <td className="px-6 py-3 text-center">{journal.credit}</td>
        <td className="px-6 py-3 text-center">{journal.currency}</td>
      </tr>
    ));
  }

  const debitSubtotal = pairs.reduce((sum, pair) => sum + (pair.debit || 0), 0);
  const creditSubtotal = pairs.reduce((sum, pair) => sum + (pair.credit || 0), 0);
  const total = debitSubtotal - creditSubtotal;

  const handleInputChange = (
    index: number,
    field: keyof Pair,
    value: string | number
  ) => {
    const updatedPairs = pairs.map((pair, i) =>
      i === index ? { ...pair, [field]: value } : pair
    );
    setPairs(updatedPairs);
  };

  // const addNewLine = () => {
  //   setPairs([...pairs, { debit_account: "", credit_account: "", debit: 0, credit: 0 }]);
  // };

  // const removePair = (index: number) => {
  //   setPairs(pairs.filter((_, i) => i !== index));
  // };

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error) || isAxiosError<ValidationErrors>(error)) {
      console.log(error);
    } else {
      console.log(error);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedCompany) {
      showSnackBar("Please select a company", "error", { vertical: "top", horizontal: "right" }, 3000);
      return;
    }

    const payload: DoubleEntryJournalCreateRequest = {
      company_id: selectedCompany.id,
      journal_number: journalNumber,
      date: date,
      page_id: selectedPage,
      description: description,
      document_number: documentNumber,
      currency: currency,
      dr_id: Number(pairs[0].debit_account),
      cr_id: Number(pairs[0].credit_account),
      debit: pairs[0].debit,
      credit: pairs[0].credit,
      balance: total,
    };

    console.log(payload, "payload");

    addJournalMutation.mutate(payload);
  };

  if (errorAccounts || errorCurrencies || errorJournals) {
    console.error(errorAccounts || errorCurrencies);
    return <FallbackPage onRetry={() => window.location.reload()} />;
  }

  if (isLoadingAccounts || isLoadingCurrencies || isLoadingJournals) {
    return <LoadingPage />;
  }

  return (
    <section className="mt-20 bg-gray-50 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 relative shadow-md rounded-lg overflow-hidden">
        <div className="py-3 px-4 mx-auto">
          <div className="w-full flex justify-between items-center">
          <h2 className="mb-8 text-xl font-bold text-gray-900 dark:text-white">
            Create Journal Entry
          </h2>
            {/* <button
              type="button"
              onClick={addNewLine}
              className="text-white bg-blue-500 px-4 py-2 rounded"
            >
              Add Line
            </button> */}
          </div>
         
          <form onSubmit={onSubmit} className="space-y-4 lg:space-y-6">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="px-4 py-3 text-center">Journal Number</th>
                  <th className="px-4 py-3 text-center">Date</th>
                  <th className="px-4 py-3 text-center">Debit Account</th>
                  <th className="px-4 py-3 text-center">Credit Account</th>
                  <th className="px-4 py-3 text-center">Document Number</th>
                  <th className="px-4 py-3 text-center">Description</th>
                  <th className="px-4 py-3 text-center">Debit</th>
                  <th className="px-4 py-3 text-center">Credit</th>
                  <th className="px-4 py-3 text-center">Currency</th>
                </tr>
              </thead>
              <tbody>
                {pairs?.map((pair, index) => (
                  <tr
                    key={index}
                    className="bg-white dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="p-1">
                      <input
                        type="text"
                        value={journalNumber}
                        onChange={(e) => setJournalNumber(e.target.value)}
                        placeholder="Journal No"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        placeholder="Date"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="p-1">
                      <select
                        value={pair.debit_account}
                        onChange={(e) =>
                          handleInputChange(index, "debit_account", e.target.value)
                        }
                        style={{width:"9.375rem"}}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      >
                        <option value="" disabled>
                          Select Account
                        </option>
                        {accounts?.data?.map((acc) => (
                          <option key={acc.id} value={acc.id}>
                            {acc.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="p-1">
                      <select
                        value={pair.credit_account}
                        onChange={(e) =>
                          handleInputChange(index, "credit_account", e.target.value)
                        }
                        style={{width:"9.375rem"}}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      >
                        <option value="" disabled>
                          Select Account
                        </option>
                        {accounts?.data?.map((acc) => (
                          <option key={acc.id} value={acc.id}>
                            {acc.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        value={documentNumber}
                        onChange={(e) => setDocumentNumber(e.target.value)}
                        placeholder="Document Number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="number"
                        value={pair.debit}
                        onChange={(e) =>
                          handleInputChange(index, "debit", Number(e.target.value))
                        }
                        min="0"
                        step="any"
                        placeholder="Debit"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="number"
                        value={pair.credit}
                        onChange={(e) =>
                          handleInputChange(index, "credit", Number(e.target.value))
                        }
                        min="0"
                        step="any"
                        placeholder="Credit"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="p-1">
                      <select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                      >
                        {currencies?.data?.map((curr, index) => (
                          <option key={index} value={curr.currency}>
                            {curr.currency}
                          </option>
                        ))}
                      </select>
                    </td>
                    {/* <td className="p-1">
                      <button
                        type="button"
                        onClick={() => removePair(index)}
                        className="text-white bg-red-500 px-4 py-2 rounded"
                      >
                        Remove
                      </button>
                    </td> */}
                  </tr>
                ))}
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></th>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Subtotal
                  </td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {debitSubtotal}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {creditSubtotal}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></th>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Total
                  </td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {total.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {total.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button
                type="submit"
                className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Submit Entry
              </button>
            </div>
          </form>
        </div>

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-4 py-3">Journal Number</th>
              <th className="px-4 py-3 text-center">Date</th>
              <th className="px-4 py-3">Debit Account</th>
              <th className="px-4 py-3">Credit Account</th>
              <th className="px-4 py-3">Document Number</th>
              <th className="px-4 py-3 text-center">Description</th>
              <th className="px-4 py-3">Debit</th>
              <th className="px-4 py-3">Credit</th>
              <th className="px-4 py-3">Currency</th>
              <th className="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
              {
                renderJournalEntries()
              }
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default JournalItem;
