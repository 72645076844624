import React from 'react';
import logo from '@/assets/logo.png';
import { Link } from 'react-router-dom';
import { RoutesPathList } from '@/config/routes/Routes';

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100 p-5">
      <img src={logo} alt="Logo" className="mb-5" />
      <h1 className="text-2xl font-bold mb-3">404</h1>
      <p className="text-lg text-gray-700">
        Page not found.
      </p>
      <Link to={RoutesPathList.Landing} className="text-primary-600 hover:underline mt-5">Home</Link>
    </div>
  );
}

export default NotFound;
