import { z, ZodSchema } from "zod";

export type Contact = {
    id: number;
    company: number;
    type: "customer" | "vendor";
    name: string;
    email: string;
    tax_number?: string | null;
    phone?: string | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    zip_code?: string | null;
    country?: string | null;
    website?: string | null;
    currency: string;
    reference?: string | null;
}

export type ContactCreateRequest = {
    type: "customer" | "vendor";
    name: string;
    email: string;
    tax_number?: string | null;
    phone?: string | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    zip_code?: string | null;
    country?: string | null;
    website?: string | null;
    currency: string;
    reference?: string | null;
}

export type ContactUpdate = {
    type: "customer" | "vendor";
    name: string;
    email: string;
    tax_number?: string | null;
    phone?: string | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    zip_code?: string | null;
    country?: string | null;
    website?: string | null;
    currency: string;
    reference?: string | null;
}

export const ContactCreateSchema: ZodSchema<ContactCreateRequest> = z.object({
    type: z.enum(["customer", "vendor"]),
    name: z.string().max(255),
    email: z.string().email(),
    tax_number: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    state: z.string().nullable().optional(),
    zip_code: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    website: z.string().url().nullable().optional(),
    currency: z.string().max(3),
    reference: z.string().nullable().optional(),
});

export const ContactUpdateSchema: ZodSchema<ContactCreateRequest> = z.object({
    type: z.enum(["customer", "vendor"]),
    name: z.string().max(255),
    email: z.string().email(),
    tax_number: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    state: z.string().nullable().optional(),
    zip_code: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    website: z.string().url().nullable().optional(),
    currency: z.string().max(3),
    reference: z.string().nullable().optional(),
});
