import { CompanyUser } from '@/types/Company'
import React from 'react'

interface CompanyUserProps {
    user: CompanyUser
}
const CompanyUserItem: React.FC<CompanyUserProps> = ({ user }) => {

    const toggleEditModal = () => {
        console.log('Edit user')
    }

    const toggleDeleteModal = () => {
        console.log('Delete user')
    }

    const getFullName = () => {
        return `${user.first_name} ${user.last_name}`
    }

    const fullName = getFullName()

    return (
        <tr
            className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
        >
            <th
                scope="row"
                className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
                {user?.id}
            </th>
            <td className="px-4 py-3">{fullName}</td>
            <td className="px-4 py-3">{user.email}</td>
            <td className="px-4 py-3">{user.is_active ? "Active" : "Pending"}</td>
            <td className="px-4 py-3 text-right">
                <button
                    onClick={toggleEditModal}
                    className="font-medium text-green-400 dark:text-green-400 hover:underline"
                >
                    Edit
                </button>
                <span className="mx-2">|</span>
                <button
                    onClick={toggleDeleteModal}
                    className="font-medium text-red-600 dark:text-red-500 hover:underline"
                >
                    Delete
                </button>

            </td>
        </tr>
    )
}

export default CompanyUserItem
