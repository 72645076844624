import { z, ZodSchema } from "zod";

export type Category = {
    id: number;
    company: number;
    name: string;
    type: "Income" | "Expense" | "Item" | "Other";
}

export type CategoryCreateRequest = {
    name: string;
    type: "Income" | "Expense" | "Item" | "Other";
}

export type CategoryUpdate = {
    name: string;
    type: "Income" | "Expense" | "Item" | "Other";
}

export const CategoryCreateSchema: ZodSchema<CategoryCreateRequest> = z.object({
    name: z.string().max(255),
    type: z.enum(["Income", "Expense", "Item", "Other"]),
});

export const CategoryUpdateSchema: ZodSchema<CategoryCreateRequest> = z.object({
    name: z.string().max(255),
    type: z.enum(["Income", "Expense", "Item", "Other"]),
});
