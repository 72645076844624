import { z, ZodSchema } from "zod";

export type Department = {
    id: number;
    company: number;
    name: string;
    description?: string | null;
}

export type DepartmentCreateRequest = {
    name: string;
    description?: string | null;
}

export type DepartmentUpdate = {
    name: string;
    description?: string | null;
}

export const DepartmentCreateSchema: ZodSchema<DepartmentCreateRequest> = z.object({
    name: z.string().max(255),
    description: z.string().nullable().optional(),
});

export const DepartmentUpdateSchema: ZodSchema<DepartmentCreateRequest> = z.object({
    name: z.string().max(255),
    description: z.string().nullable().optional(),
});
