import { RoutesPathList } from "@/config/routes/Routes";
import { RootState } from "@/store";
import React, { Fragment, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Sidebar: React.FC<SidebarProps> = () => {
  const { selectedCompany } = useSelector((state: RootState) => state.company);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <aside
      id="default-sidebar"
      className={"fixed top-14 left-0 z-40 w-64 h-screen transition-transform"}
      style={{ transition: "0.5s ease" }}
      aria-label="Sidebar"
    >
      <div
        className={
          "h-[100%] px-3 py-4 bg-white border-r dark:border-none dark:bg-gray-800"
        }
      >
        <ul className="space-y-2 font-medium h-[90%]">
          <li className="mt-2">
            <NavLink
              to={RoutesPathList.Payroll}
              className={(link) =>
                `${
                  link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
              }
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
                />
              </svg>

              <span className="ms-3">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={RoutesPathList.CompanyDasboard}
              className={(link) =>
                `${
                  link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
              }
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 12c.263 0 .524-.06.767-.175a2 2 0 0 0 .65-.491c.186-.21.333-.46.433-.734.1-.274.15-.568.15-.864a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 12 9.736a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 16 9.736c0 .295.052.588.152.861s.248.521.434.73a2 2 0 0 0 .649.488 1.809 1.809 0 0 0 1.53 0 2.03 2.03 0 0 0 .65-.488c.185-.209.332-.457.433-.73.1-.273.152-.566.152-.861 0-.974-1.108-3.85-1.618-5.121A.983.983 0 0 0 17.466 4H6.456a.986.986 0 0 0-.93.645C5.045 5.962 4 8.905 4 9.736c.023.59.241 1.148.611 1.567.37.418.865.667 1.389.697Zm0 0c.328 0 .651-.091.94-.266A2.1 2.1 0 0 0 7.66 11h.681a2.1 2.1 0 0 0 .718.734c.29.175.613.266.942.266.328 0 .651-.091.94-.266.29-.174.537-.427.719-.734h.681a2.1 2.1 0 0 0 .719.734c.289.175.612.266.94.266.329 0 .652-.091.942-.266.29-.174.536-.427.718-.734h.681c.183.307.43.56.719.734.29.174.613.266.941.266a1.819 1.819 0 0 0 1.06-.351M6 12a1.766 1.766 0 0 1-1.163-.476M5 12v7a1 1 0 0 0 1 1h2v-5h3v5h7a1 1 0 0 0 1-1v-7m-5 3v2h2v-2h-2Z"
                />
              </svg>

              <span className="flex-1 ms-3 whitespace-nowrap">Companies</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={RoutesPathList.CompanyUsers}
              className={(link) =>
                `${
                  link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
              }
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                />
              </svg>
              <span className="flex-1 ms-3 whitespace-nowrap">Users</span>
            </NavLink>
          </li>

          {selectedCompany !== null ? (
            <Fragment>
              <li className="relative group" 
              onMouseEnter={() => {setIsDropdownVisible(true)}}
              >
                <div className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                  <div className="flex">
                    <svg
                      className="text-gray-800 dark:text-white w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 15V9m4 6V9m4 6V9m4 6V9M2 16h16M1 19h18M2 7v1h16V7l-8-6-8 6Z"
                      />
                    </svg>
                  </div>
                  <span className="flex-1 ms-3 whitespace-nowrap">Banking</span>
                  <svg
                    className="w-[24px] h-[24px] text-gray-800 dark:text-white transition-transform"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m9 5 7 7-7 7"
                    />
                  </svg>
                </div>

                {/* Dropdown menu */}
                <div
                  className={`${isDropdownVisible ? "":"hidden"} ml-[0.75rem] absolute left-full -top-[0.625rem] w-auto transform transition-transform duration-300 ease-in-out opacity-0 translate-x-[-20px] group-hover:translate-x-0 group-hover:opacity-100 group-hover:pointer-events-auto bg-white dark:bg-gray-800 p-2 rounded-lg shadow-md`}
                  onMouseLeave={() => {
                    setIsDropdownVisible(false)
                  }}
                >
                  <ul className="flex flex-col gap-2">
                    <li>
                      <NavLink
                        to={RoutesPathList.Accounts}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Accounts
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={RoutesPathList.Transfers}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Transfers
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={RoutesPathList.Reconciliations}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Reconciliations
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={RoutesPathList.Transactions}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Transactions
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <NavLink
                  to={RoutesPathList.Billing}
                  className={(link) =>
                    `${
                      link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                    } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
                  }
                >
                  <svg
                    className="w-[24px] h-[24px] text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"
                    />
                  </svg>

                  <span className="flex-1 ms-3 whitespace-nowrap">Billing</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={RoutesPathList.DoubleEntry}
                  className={(link) =>
                    `${
                      link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                    } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
                  }
                >
                  <svg
                    className="text-gray-800 dark:text-white w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M2 19h16m-8 0V5m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 8l-2.493 5.649A1 1 0 0 0 2.443 15h3.114a1.001 1.001 0 0 0 .936-1.351L4 8Zm0 0V6m12 2-2.493 5.649A1 1 0 0 0 14.443 15h3.114a1.001 1.001 0 0 0 .936-1.351L16 8Zm0 0V6m-4-2.8c3.073.661 3.467 2.8 6 2.8M2 6c3.359 0 3.192-2.115 6.012-2.793"
                    ></path>
                  </svg>

                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Double Entry
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={RoutesPathList.Items}
                  className={(link) =>
                    `${
                      link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                    } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
                  }
                >
                  <svg
                    className="text-gray-800 dark:text-white w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 8v1h4V8m4 7H4a1 1 0 0 1-1-1V5h14v9a1 1 0 0 1-1 1ZM2 1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"
                    ></path>
                  </svg>

                  <span className="flex-1 ms-3 whitespace-nowrap">Items</span>
                </NavLink>
              </li>

              <li
                className="relative group"
                onMouseEnter={() => {setIsDropdownVisible(true)}}
              >
                <div className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                  <div className="flex">
                    <svg
                      className="text-gray-800 dark:text-white w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.333 6.764a3 3 0 1 1 3.141-5.023M2.5 16H1v-2a4 4 0 0 1 4-4m7.379-8.121a3 3 0 1 1 2.976 5M15 10a4 4 0 0 1 4 4v2h-1.761M13 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"
                      />
                    </svg>
                  </div>
                  <span className="flex-1 ms-3 whitespace-nowrap">HR</span>
                  <svg
                    className="w-[24px] h-[24px] text-gray-800 dark:text-white transition-transform"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m9 5 7 7-7 7"
                    />
                  </svg>
                </div>

                {/* Dropdown menu */}
                <div
                  className={`${isDropdownVisible ? "":"hidden"} ml-[0.75rem] absolute left-full -top-[0.625rem] w-auto transform transition-transform duration-300 ease-in-out opacity-0 translate-x-[-20px] group-hover:translate-x-0 group-hover:opacity-100 group-hover:pointer-events-auto bg-white dark:bg-gray-800 p-2 rounded-lg shadow-md`}
                  onMouseLeave={() => {
                    setIsDropdownVisible(false)
                  }}
                >
                  <ul className="flex flex-col gap-2">
                    <li>
                      <NavLink
                        to={RoutesPathList.Employees}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Employees
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="relative group"
              onMouseEnter={() => {setIsDropdownVisible(true)}}>
                <div className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                  <div className="flex">
                    <svg
                      className="w-[24px] h-[24px] text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                      />
                    </svg>
                  </div>
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Settings
                  </span>
                  <svg
                    className="w-[24px] h-[24px] text-gray-800 dark:text-white transition-transform"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m9 5 7 7-7 7"
                    />
                  </svg>
                </div>

                {/* Dropdown menu */}
                <div
                  className={`${isDropdownVisible ? "":"hidden"} ml-[0.75rem] absolute left-full -top-[0.625rem] w-auto transform transition-transform duration-300 ease-in-out opacity-0 translate-x-[-20px] group-hover:translate-x-0 group-hover:opacity-100 group-hover:pointer-events-auto bg-white dark:bg-gray-800 p-2 rounded-lg shadow-md`}
                  onMouseLeave={() => {
                    setIsDropdownVisible(false)
                  }}
                >
                  <ul className="flex flex-col gap-2">
                    <li>
                      <NavLink
                        to={RoutesPathList.Taxes}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Taxes
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to={RoutesPathList.Contacts}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Contacts
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to={RoutesPathList.Departments}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Departments
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to={RoutesPathList.ExchangeRates}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Exchange Rates
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to={RoutesPathList.Categories}
                        className={(link) =>
                          `${
                            link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                          } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`
                        }
                      >
                        <span className="flex-1 ms-3 whitespace-nowrap">
                          Categories
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <NavLink
                  to={RoutesPathList.Documents}
                  className={(link) =>
                    `${
                      link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                    } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
                  }
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"
                    />
                  </svg>

                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Documents
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={RoutesPathList.Pages}
                  className={(link) =>
                    `${
                      link?.isActive ? "bg-gray-100 dark:bg-gray-700" : ""
                    } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`
                  }
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"
                    />
                  </svg>

                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Journal Pages
                  </span>
                </NavLink>
              </li>
            </Fragment>
          ) : null}
        </ul>
        <div className="flex-1"></div>
        {/* <p className="text-start text-gray-400 dark:text-gray-400">v0.0.7</p> */}
      </div>
    </aside>
  );
};

export default Sidebar;
