import React from 'react';
import logo from '@/assets/logo.png';
import logo_white from '@/assets/logo_white.png';
import { useNavigate } from 'react-router-dom';
import { RoutesPathList } from '@/config/routes/Routes';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

export interface LogoProps {
    className?: string;
    size: {
        width: string;
        height: string;
    };
}

const Logo: React.FC<LogoProps> = ({ className, size }) => {

    const navigate = useNavigate();
    const { theme } = useSelector((state: RootState) => state.theme);

    const currentLogo = theme === 'dark' ? logo_white : logo;

    return (
        <div className={className ?? ""}>
            <div onClick={() => navigate(RoutesPathList.Landing)} className="inline-flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
                <img src={currentLogo} height={size.height} width={size.width} className={`ml-4 cursor-pointer`} />
            </div>
        </div>
    );
};


export default Logo;
