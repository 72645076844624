import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getData, saveData } from "@/utils/localStorage";
import { companyStorageKey } from "@/config/constants/StorageKeys";
import { Company, CompanySliceState } from "@/types/Company";


const initialCompanyState: CompanySliceState = {
    selectedCompany: getData<Company>(companyStorageKey),
}

const companySlice = createSlice({
    name: 'company',
    initialState: initialCompanyState,
    reducers: {
        setSelectedCompany(state, action: PayloadAction<Company | null>) {
            state.selectedCompany = action.payload;
            saveData(companyStorageKey, action.payload)
        }
    }
})

export const { setSelectedCompany } = companySlice.actions
export default companySlice

