import React, { Fragment } from 'react';
import bannerMac from '@/assets/bannerMac.png';


const Accounting: React.FC = () => {
    return (
        <Fragment>
            <section className="bg-secondary-700">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto gap-8 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-primary-700">
                            Accounting tool for <span className="element"></span>
                        </h1>
                        <p className="max-w-2xl mb-6 font-light text-textcolor-700 md:text-lg lg:text-xl">
                            From Automated Accounting, to Journal Entries, to Balance Sheets - we’ve got everything you will need to run the financials of your business.
                        </p>
                        <div className="flex flex-row items-center space-x-5">
                            <a
                                href="#"
                                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-blue-800 hover:bg-text-color-700 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                            >
                                Book Your Demo
                                <svg
                                    className="w-5 h-5 ml-2 -mr-1"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="hidden lg:col-span-5 lg:flex lg:mt-0">
                    <img src={bannerMac} alt="mockup" />
                    </div>
                </div>
            </section>

            <section className="bg-blue-700">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-10 lg:px-6">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
                        All accounting features your business will ever need
                    </h2>
                    <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                        <div className="text-white">
                            <svg className="mx-auto mb-4 w-12 h-12 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z" clipRule="evenodd"></path><path fillRule="evenodd" d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z" clipRule="evenodd"></path><path fillRule="evenodd" d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            <h3 className="mb-2 text-xl font-bold">Automated Transactions</h3>
                            <p className="mb-4">Whether you have 1 transaction or 100 - we want to make sure you can do both extremely fast</p>
                        </div>
                        <div className="text-white">
                            <svg className="mx-auto mb-4 w-12 h-12 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path></svg>
                            <h3 className="mb-2 text-xl font-bold">Journal Entries Simplified</h3>
                            <p className="mb-4">Fast and simplified - get all your entries filled out as quickly as it takes to brew yourself some coffee</p>
                        </div>
                        <div className="text-white">
                            <svg className="mx-auto mb-4 w-12 h-12 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                            <h3 className="mb-2 text-xl font-bold text-white">Easy Onboarding</h3>
                            <p className="mb-4">Have years worth of accounting data? No problem - get everything transferred over in less than 30 minutes.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="max-w-screen-md mb-8 lg:mb-16">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Designed for a business like yours.</h2>
                    <p className="text-gray-500 sm:text-xl dark:text-gray-400">Whether you have few journal entries and transactions, or thousands a day - PandaTax was designed to handle any workload your throw at it.</p>
                </div>
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0">
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                            <svg className="w-10 h-10 text-primary-600 lg:w-6 lg:h-" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M4 5a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z" clipRule="evenodd"/>
                                <path fillRule="evenodd" d="M5 14c0-.6.4-1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0c0-.6.4-1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z" clipRule="evenodd"/>
                            </svg>
                        </div>
                        <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">Multiple Transactions Simplified</h3>
                        <p className="text-gray-500 dark:text-gray-400">Built for even the most robust accounting departments - handle hundreds of transactions in mere minutes, without all the tedious and manual fill-ins.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                            <svg className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v15c0 .6.4 1 1 1h15M8 16l2.5-5.5 3 3L17.3 7 20 9.7"/>
                            </svg>
                        </div>
                        <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">Double Entry System</h3>
                        <p className="text-gray-500 dark:text-gray-400">Detailed double entry system and chart of accounts to generate whatever form/transaction your business needs</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                            <svg className="w-6 h-6 text-blue-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3c.6 0 1 .4 1 1v15c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1V5c0-.6.4-1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z"/>
                            </svg>
                        </div>
                        <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">Detailed Reports on all your Business Financials</h3>
                        <p className="text-gray-500 dark:text-gray-400">General Ledger, Balance Sheet, Profit & Loss - you name it, there’s a report for it.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                            <svg className="w-6 h-6 text-blue-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M3 21h18M4 18h16M6 10v8m4-8v8m4-8v8m4-8v8M4 9.5v-1c0-.3.2-.6.5-.8l7-4.5a1 1 0 0 1 1 0l7 4.5c.3.2.5.5.5.8v1c0 .3-.2.5-.5.5h-15a.5.5 0 0 1-.5-.5Z"/>
                            </svg>
                        </div>
                        <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">Detailed Banking View</h3>
                        <p className="text-gray-500 dark:text-gray-400">From Transfers, to your accounts, to Reconciliations - you can see it all with the click of a button.</p>
                    </div>
                </div>
            </section>

            <section className="bg-white py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="max-w-4xl text-center mx-auto">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Let’s show you the power of PandaTax.</h2>
                    <p className="mb-6 font-light text-gray-500 sm:text-xl dark:text-gray-400">We’re here to help make running your business easier, and be the backbone of your Financial Department. Give us a chance to show you what we can do, and we promise you won’t be disappointed.</p>
                    <p className="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">Schedule your free Demo Call below, and let’s get started!</p>

                    <div className="flex flex-col space-y-4 sm:flex-row justify-center sm:space-y-0 sm:space-x-4">
                        <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-text-color-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                            Book Your Demo
                            <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Accounting;
