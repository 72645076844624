import React, { Fragment } from 'react'
import bannerMac from '../assets/bannerMac.png';


const Payroll: React.FC = () => {
    return (
        <Fragment>
            <section className="bg-secondary-700">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto gap-8 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-primary-700">
                            Payroll tool for <span className="element"></span>
                        </h1>
                        <p className="max-w-2xl mb-6 font-light text-textcolor-700 md:text-lg lg:text-xl">
                            Handle all your employees needs, from vacation days to a 13th Salary/Bonus in one integrated dashboard directly linked with your Accounting.
                        </p>
                        <div className="flex items-center space-x-5">
                            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                                Book Your Demo
                                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="hidden lg:flex lg:mt-0 lg:col-span-5">
                        <img src={bannerMac} alt="mockup" />
                    </div>
                </div>
            </section>

            <section className="bg-blue-700">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div className="max-w-screen-md mb-8 lg:mb-16">
                        <h2 className="mb-4 text-4xl font-extrabold text-white">Designed for a Business Like Yours</h2>
                        <p className="text-white sm:text-xl">We’ve simplified Payroll so it can be done in a few clicks. It’s a simple yet critical part of your business - and that’s why we want to make you have 100% clarity on what’s going on in your business.</p>
                    </div>
                    <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0">
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                                <svg className="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M8 5c0-.6.4-1 1-1h11a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-1a1 1 0 1 1 0-2h1V6H9a1 1 0 0 1-1-1Z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M4 7a2 2 0 0 0-2 2v9c0 1.1.9 2 2 2h11a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H4Zm0 11v-5.5h11V18H4Z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <h3 className="mb-2 text-xl font-bold text-white dark:text-white">Integrated with your Accounting</h3>
                            <p className="text-white dark:text-gray-400">What you do for payroll is instantly transferred to your PandaTax Accounting.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                                <svg className="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z" />
                                </svg>
                            </div>
                            <h3 className="mb-2 text-xl font-bold text-white dark:text-white">Lightning fast with no hiccups</h3>
                            <p className="text-white dark:text-gray-400">Handling each individual employees’ needs is tedious - that’s why we’ve made sure everything is responsive and fast so you can be in and out.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                                <svg className="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" strokeWidth="2" d="M10 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h2m10 1a3 3 0 0 1-3 3m3-3a3 3 0 0 0-3-3m3 3h1m-4 3a3 3 0 0 1-3-3m3 3v1m-3-4a3 3 0 0 1 3-3m-3 3h-1m4-3v-1m-2.1 1.9-.7-.7m5.6 5.6-.7-.7m-4.2 0-.7.7m5.6-5.6-.7.7M12 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                            </div>
                            <h3 className="mb-2 text-xl font-bold text-white dark:text-white">All your employees needs made easy </h3>
                            <p className="text-white dark:text-gray-400">From 13th Salaries, to Sick Days, to Bonuses - whatever your employee needs, you can do it inside our payroll app.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                                <svg className="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeWidth="2" d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                            </div>
                            <h3 className="mb-2 text-xl font-bold text-white dark:text-white">Dedicated Employee View</h3>
                            <p className="text-white dark:text-gray-400">Want to see how many hours an employee has worked, or how many days they have left? You can find each employee individually through our HR feature.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div className="max-w-4xl text-center mx-auto">
                        <h2 className="mb-4 text-4xl font-extrabold text-gray-900">Let’s show you the power of PandaTax.</h2>
                        <p className="mb-6 font-light text-gray-500 sm:text-xl">We’re here to help make running your business easier, and be the backbone of your Financial Department. Give us a chance to show you what we can do, and we promise you won’t be disappointed.</p>
                        <p className="mb-8 font-light text-gray-500 sm:text-xl">Schedule your free Demo Call below, and let’s get started!</p>

                        <div className="flex flex-col space-y-4 sm:flex-row justify-center sm:space-y-0 sm:space-x-4">
                            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                                Book Your Demo
                                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Payroll