import GenericApi from '@/api/genericApi';
import FormField from '@/components/CustomForm/FormField';
import { ApiRoutes, composeRoute } from '@/config/routes/ApiRoutes';
import { RoutesPathList } from '@/config/routes/Routes';
import { useSnackBarAlert } from '@/hooks/useSnackbar';
import { ApiResponse, ApiError } from '@/types/Api';
import { ChangePasswordRequest, ChangePasswordRequestSchema } from '@/types/Auth';
import { User } from '@/types/User';
import { FormError, ValidationErrors } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { formatError } from '@/utils/formatError';
import { generateChangePasswordInputs } from '@/utils/generateInputs';
import { getUser, updateUserData } from '@/utils/localStorage';
import { wait } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';


const PasswordUser: React.FC = () => {

    const navigate = useNavigate()
    const user = getUser();
    const { showSnackBar } = useSnackBarAlert()

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<ChangePasswordRequest>({
        resolver: zodResolver(ChangePasswordRequestSchema),
    });

    const changePasswordInputs = generateChangePasswordInputs();

    const route = composeRoute(ApiRoutes.USERS, user?.id ?? null, ApiRoutes.PASSWORD)
    const changePasswordMutation = useMutation<ApiResponse<User>, ApiError<ValidationErrors>, ChangePasswordRequest>(
        {
            mutationFn: (data) => GenericApi.put<User>(route, {
                ...data
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                const isUserUpdated = updateUserData(data);
                if (isUserUpdated) {
                    CustomLogger.log('User password updated successfully')
                    await wait(1000)
                    navigate(RoutesPathList.CompanyDasboard, { replace: true })
                } else {
                    CustomLogger.error('Failed to update user data')
                    showSnackBar('Failed to update user password', 'error', {
                        horizontal: 'right',
                        vertical: 'top'
                    }, 2000)
                }
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        const errorMessage = "An error occurred while changing password"
        if (isAxiosError<FormError>(error)) {
          setError('root', {
            type: 'manual',
            message: error.response?.data?.detail ?? errorMessage
          })
          return
        }
    
        if (isAxiosError<ValidationErrors>(error)) {
          setError('root', {
            type: 'manual',
            message: formatError(error, errorMessage)
          })
          return
        }
    
        //* Set error message from server
        setError('root', {
          type: 'manual',
          message: error?.message ?? errorMessage
        })
      }

    const onSubmit = (data: ChangePasswordRequest) => {
        changePasswordMutation.mutate(data)
    }

    return (
        <div className="w-full p-6 mt-16 bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h2 className="mb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Change Password
            </h2>
            {changePasswordMutation.isError && (
                <div className="alert alert-error">
                    {errors.root?.message}
                </div>
            )}
            {changePasswordMutation.isSuccess && (
                <div className="alert alert-success">
                    Password changed successfully
                </div>
            )}
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit(onSubmit)}>
                {
                    changePasswordInputs.map((input, index) => (
                        <FormField<ChangePasswordRequest>
                            key={index}
                            label={input.label}
                            required={input.required}
                            error={errors[input.name as keyof ChangePasswordRequest]}
                            name={input.name}
                            placeholder={input.placeholder}
                            register={register}
                            type={input.type}
                            valueAsNumber={input.valueAsNumber}
                            inputStyle='bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                        />))
                }
                <button
                    type="submit"
                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                     {
                        changePasswordMutation.isPending ?
                            <div className='flex justify-center items-center gap-4'>
                                Changing Password
                                <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg>
                            </div>
                            : 'Change Password'
                    }
                </button>
            </form>
        </div>
    );
};

export default PasswordUser;
