import React, { Fragment } from 'react';
import bannerMac from '../assets/bannerMac.png';

const Landing: React.FC = () => {
  return (
    <Fragment>
      <section className="bg-secondary-700">
        <div className="grid max-w-screen-xl px-4 py-20 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 xl:py-24">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-blue-700">
              The all-in-one accounting solution to handle everything your business needs.
            </h1>
            <p className="max-w-2xl mb-6 font-light primarylg:mb-8 md:text-lg lg:text-xl text-textcolor-700">
              From accounting and payroll, to invoicing - PandaTax is designed with you in mind. To streamline all your financials so you can focus on your business.
            </p>
            <div className="flex flex-row items-center space-x-5">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book your Demo here
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={bannerMac} alt="mockup" />
          </div>
        </div>
      </section>

      <section className="bg-blue-700 mt-6">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-10 lg:px-6">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
            The most Complete Accounting Software on the Market
          </h2>
          <p className="sm:text-xl text-white">We make sure we have you covered in all areas.</p>
          <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 20"
              >
                <path d="M16 14V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 0 0 0-2h-1v-2a2 2 0 0 0 2-2ZM4 2h2v12H4V2Zm8 16H3a1 1 0 0 1 0-2h9v2Z" />
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">Accounting</h3>
              <p className="mb-4 text-white">
                From journal entries, to transactions, to detailed tax reports - we have everything to run your accounting department.
              </p>
              <a
                href="/accounting"
                className="inline-flex items-center text-sm font-medium text-white hover:text-textcolor-700"
              >
                Learn more about Accounting feature
                <svg
                  className="ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z" />
                <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z" />
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">Payroll</h3>
              <p className="mb-4 text-white">
                Whether you’re giving bonuses, a 13th salary, or anything in between - with just a few clicks you can handle all your employees needs.
              </p>
              <a
                href="/payroll"
                className="inline-flex items-center text-sm font-medium text-white hover:text-textcolor-700"
              >
                Learn more about Payroll feature
                <svg
                  className="ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="m0 8.535 7.463 3.762-7.45 3.75c-.026-.17-.013-7.57-.013-7.57Zm10.863 2.837 7.137-3.618v7.237L9 20v-7.627l1.863-.001ZM8.59 11.557 1.002 15.36 8.59 19.2v-7.643Zm8.137-9.43A1.843 1.843 0 1 1 14.884 0a1.843 1.843 0 0 1 1.843 1.843ZM1.078 1.747l.02 6.513L9 10.66V4.258L1.078 1.747ZM10.274 4.3V10.7l6.648-3.38V.92L10.274 4.3Z" />
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">Invoices</h3>
              <p className="mb-4 text-white">
                Simple and quick invoicing, with the benefit of tracking and monitoring your accounts receivables.
              </p>
              <a
                href="/invoices"
                className="inline-flex items-center text-sm font-medium text-white hover:text-textcolor-700"
              >
                Learn more about Invoices feature
                <svg
                  className="ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-20 sm:py-16 lg:px-6">
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">Modern Solutions for Modern Problems</h2>
              <p className="mb-8 font-light lg:text-xl">We understand that the financial world is constantly evolving. Managing your accounts, payroll and invoicing becomes increasingly more frustrating, and most solutions are slow, clunky, and not user friendly.</p>
              <p className="mb-8 font-light lg:text-xl">That’s why we focus on functionality and ease of use, so you can do what you need to do in minutes, not hours.</p>
              <ul role="list" className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700">
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Automatic Currency Conversion</span>
                </li>
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Automated Transactions and Journal Entries</span>
                </li>
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Detailed Reports, Exports, and all your data accessible with 1-click</span>
                </li>
              </ul>
              <p className="mb-0 font-light lg:text-xl">And that’s not all. </p>
              <p className="mb-0 font-light lg:text-xl">Book your Demo with us, and we’ll show you what PandaTax can do for your business.</p>
              {/* <a href="#" className="inline-block mt-8 px-6 py-3 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                Book a Demo
                            </a> */}
            </div>
            <img className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-1.png" alt="office feature image" />
          </div>
          <div className="gap-8 items-center sm:pt-10 lg:grid lg:grid-cols-2 xl:gap-16 pt-16">
            <img className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-2.png" alt="office feature image 2" />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">We invest in the future of accounting.</h2>
              <p className="mb-8 font-light lg:text-xl">Gone are the days where you mulled over spreadsheets, and outdated software that takes minutes to load. No more crashes that corrupt your precious data, and random freezes that delay you getting back to ‘doing business.’</p>
              <ul role="list" className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700">
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">99.9% server uptime</span>
                </li>
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Safe Cloud Storage for your Data</span>
                </li>
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Instant response time, and fast loading</span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">You have to move fast. Needless delays, freezing and crashing are not an option.</p>
              {/* <a href="#" className="inline-block mt-8 px-6 py-3 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                Learn More
                            </a> */}
            </div>
          </div>
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16 pt-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">Work with one software that does it all.</h2>
              <p className="mb-8 font-light lg:text-xl">Speed meets simplicity. Our goal is to ensure that you can do everything you need with one system only. With PandaTax, you do not need to combine multiple applications and spreadsheets. You can run all your financial records under one roof.</p>
              <ul role="list" className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700">
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Fully Fledged Accounting Software</span>
                </li>
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Payroll System to manage all your needs</span>
                </li>
                <li className="flex space-x-3">
                  <svg className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Invoicing software to tie up all loose ends</span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">One 'room' leads to the other. No lost data, no lost time.</p>
              <p className="mb-8 font-light lg:text-xl">Book your demo today and let’s show you what PandaTax can do.</p>
              {/* <a href="#" className="inline-block mt-8 px-6 py-3 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                Book a Demo
                            </a> */}
            </div>
            <img className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-1.png" alt="office feature image" />
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-10 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-blue-700">We help <span id="element"></span></h2>
            <p className="mb-6 font-light text-gray-700 max-w-4xl mx-auto">With 2024 underway, it’s time you upgraded your business to the next level. And we would be honored to be a part of your journey.</p>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-blue-700 border border-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
              Book your demo
              <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Landing;
