import { CustomModalPosition } from '@/types/Modal'
import { Modal } from 'flowbite-react'
import React from 'react'

export interface AdaptiveModalProps {
  children: React.ReactNode
  title: JSX.Element
  onClose: () => void
  isOpen: boolean
  position: CustomModalPosition
  className?: string
  footer?: JSX.Element
}

const AdaptiveModal: React.FC<AdaptiveModalProps> = ({ children, title, onClose, isOpen, position, className, footer }) => {

  const defaultClassName = 'h-full flex items-center bg-[rgba(0,0,0,0.4)]'


  return (
    <Modal
      position={position}
      dismissible
      className={className ? className : defaultClassName}
      show={isOpen}
      onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      {
        footer ?
          <Modal.Footer>
            {footer}
          </Modal.Footer>
          :
          null
      }
    </Modal>
  )
}

export default AdaptiveModal
