import GenericApi from '@/api/genericApi'
import { OrganizationQueryKey } from '@/config/constants/QueryKeys'
import { ApiRoutes } from '@/config/routes/ApiRoutes'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import { ApiResponse, ApiError } from '@/types/Api'
import { Organization as OrganizationType } from '@/types/Organization'
import { ValidationErrors, FormError } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, isAxiosError } from 'axios'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { setSelectedOrganization } from '@/store/slices/organizationSlice'
import { AdaptiveModal } from '@/components'
import { CustomModalPosition } from '@/types/Modal'
export interface OrganizationProps {
    organization: OrganizationType
}

//TODO Move to components

const Organization: React.FC<OrganizationProps> = ({ organization }) => {

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch()
    const { selectedOrganization } = useSelector((state: RootState) => state.organization)

    const deleteOrganizationMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>(
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`${ApiRoutes.ORGANIZATIONS}/${data.id}`),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [OrganizationQueryKey] })
                showSnackBar("Organization deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting organization", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const handleDelete = (id: number) => {
        deleteOrganizationMutation.mutate({ id })
    }

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleSelect = (organization: OrganizationType) => {
        dispatch(setSelectedOrganization(organization))
    }

    const selectedOrganizationStyles = selectedOrganization?.id === organization?.id ? 'font-medium bg-green-600 bg:green-500 text-white px-3 py-2 rounded-md inline-flex items-center' : 'font-medium bg-primary-600 bg:primary-500 text-white px-3 py-2 rounded-md inline-flex items-center'
    const isSelected = selectedOrganization?.id === organization?.id

    return (
        <Fragment>
            <tr className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700">
                <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {organization?.name}
                </th>
                <td className="px-4 py-3">{new Date(organization?.created_at).toLocaleDateString()}</td>
                <td className="px-4 py-3 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <button onClick={() => handleSelect(organization)} className={selectedOrganizationStyles}>
                        {
                            isSelected ? 'Selected' : 'Select'
                        }
                    </button>
                </td>
                <td className="px-4 py-3 text-right">

                    <button onClick={() => setOpenModal(true)} className="font-medium text-red-600 dark:text-red-500 hover:underline">
                        Delete
                    </button>
                </td>
            </tr>

            <AdaptiveModal
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700" onClick={() => handleDelete(organization?.id)}>
                            {
                                deleteOrganizationMutation.isPending ? <svg className="w-5 h-5 border-r-2 border-red-500 rounded-full animate-spin" viewBox="0 0 24 24"></svg> : 'Delete'
                            }
                        </button>
                        <button className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300" onClick={() => setOpenModal(false)}>
                            Cancel
                        </button>
                    </Fragment>
                }
                title={<span>Delete Organization - {organization?.name}</span>}
            >
                <p className='dark:text-white'>Are you sure you want to delete this organization?</p>
            </AdaptiveModal>
        </Fragment>
    )
}

export default Organization
