import React, { useEffect, useRef, useState } from 'react';
import Logo from './Logo';
import { RootState, useAppDispatch } from '@/store';
import { changeTheme } from '@/store/slices/themeSlice';
import { authStorageUser } from '@/config/constants/StorageKeys';
import { RoutesPathList } from '@/config/routes/Routes';
import { getUser, removeData } from '@/utils/localStorage';
import { useSelector } from 'react-redux';
import { Theme } from '@/types/Theme';
import { Link } from 'react-router-dom';
import { setSelectedCompany } from '@/store/slices/companySlice';

const TopSidebar: React.FC = () => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const avatarRef = useRef<HTMLButtonElement>(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useAppDispatch();
    const { theme } = useSelector((state: RootState) => state.theme);
    const { selectedCompany } = useSelector((state: RootState) => state.company);
    const user = getUser();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !avatarRef.current?.contains(event.target as Node)
            ) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const changeThemeHandler = () => {
        dispatch(changeTheme());
        closeDropdown();
    };

    const handleLogout = () => {
        closeDropdown();
        removeData(authStorageUser);
        window.location.href = RoutesPathList.Login;
    };


    const formatAccountName = (isOrganization: boolean) => {
        if(!user) return '';

        if (isOrganization) {
            return user?.company_name;
        }
        return `${user?.first_name} ${user?.last_name}`;
    }

    const formatAccountInitials = (isOrganization: boolean) => {
        if(!user) return '';

        if (isOrganization) {
            return user?.company_name?.[0] ?? 'C';
        }
        return `${user?.first_name[0]}${user?.last_name[0]}`;
    }

    const userFullName = formatAccountName(user?.is_organization ?? false);
    const userInitials = formatAccountInitials(user?.is_organization ?? false);

    console.log({
        userFullName,
        userInitials,
    })

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
            <div className="flex justify-between items-center">
                <div className="flex justify-start items-center">
                    <div className="flex items-center justify-between">
                        <Logo size={{
                            height: "40px",
                            width: "75px"
                        }} className="flex items-center justify-between" />
                    </div>
                </div>

                <div className="flex flex-1 justify-center">
                    {
                        selectedCompany ?
                            <div data-dismissible="chip"
                                className="relative min-w-[10rem] grid select-none items-center whitespace-nowrap rounded-lg bg-blue-500 dark:bg-blue-700 py-3 px-4  font-sans text-xs font-bold uppercase text-white">
                                <span className="mr-5 text-center">
                                    {
                                        selectedCompany?.name
                                    }
                                </span>
                                <button
                                    onClick={() => dispatch(setSelectedCompany(null))}
                                    className="!absolute top-2/4 right-1 mx-px h-5 max-h-[32px] w-5 max-w-[32px] -translate-y-2/4 select-none rounded-md text-center align-middle font-sans text-xs font-medium uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    type="button">
                                    <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4"
                                            stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </span>
                                </button>
                            </div> : null
                    }
                </div>

                <div className="flex items-center justify-center mr-5">
                    <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        <button
                            ref={avatarRef}
                            type="button"
                            onClick={toggleDropdown}
                            className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                            aria-expanded={isDropdownOpen}
                            data-dropdown-toggle="user-dropdown"
                            data-dropdown-placement="bottom"
                        >
                            <span className="sr-only">Open user menu</span>
                            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                <span className="font-medium text-gray-600 dark:text-gray-300">{userInitials}</span>
                            </div>
                        </button>

                        {isDropdownOpen && (
                            <div
                                id="user-dropdown"
                                ref={dropdownRef}
                                className="z-50 absolute top-[70%] right-2 my-4 text-base list-none border-2 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                            >
                                <div className="px-4 py-3">
                                    <span className="block text-sm text-gray-900 dark:text-white">{userFullName}</span>
                                    <span className="block text-sm text-gray-500 truncate dark:text-gray-400">{user?.email}</span>
                                </div>
                                <ul className="py-3" aria-labelledby="user-menu-button">
                                    <li onClick={changeThemeHandler} className="flex hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer justify-start items-center gap-2">
                                        {theme === Theme.LIGHT ? (
                                            <svg
                                                className="w-6 h-6 ml-2 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 21a9 9 0 0 1-.5-17.986V3c-.354.966-.5 1.911-.5 3a9 9 0 0 0 9 9c.239 0 .254.018.488 0A9.004 9.004 0 0 1 12 21Z"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                className="w-6 h-6 ml-2 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 5V3m0 18v-2M7.05 7.05 5.636 5.636m12.728 12.728L16.95 16.95M5 12H3m18 0h-2M7.05 16.95l-1.414 1.414M18.364 5.636 16.95 7.05M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                                                />
                                            </svg>
                                        )}
                                        <p className="block cursor-pointer px-4 py-2 text-sm text-gray-700 dark:text-gray-200 dark:hover:text-white">Change Theme</p>
                                    </li>
                                    <li className="flex hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer justify-start items-center gap-2">
                                        <Link
                                            onClick={closeDropdown}
                                            to={RoutesPathList.OrganizationUserProfile}
                                            className="flex hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer justify-start items-center gap-2"
                                        >
                                            <svg
                                                className="w-6 h-6 ml-2 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                />
                                            </svg>
                                            <span className="block cursor-pointer px-4 py-2 text-sm text-gray-700 dark:text-gray-200 dark:hover:text-white">{
                                                user?.is_organization ? 'Organization Profile' : 'User Profile'
                                            }</span>
                                        </Link>
                                    </li>
                                    <li className="flex hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer justify-start items-center gap-2">
                                        <Link
                                            onClick={closeDropdown}
                                            to={RoutesPathList.OrganizationUserPassword}
                                            className="flex hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer justify-start items-center gap-2"
                                        >
                                            <svg
                                                className="w-6 h-6 ml-2 text-gray-800 dark:text-white"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"
                                                />
                                            </svg>
                                            <span className="block cursor-pointer px-4 py-2 text-sm text-gray-700 dark:text-gray-200 dark:hover:text-white">Password</span>
                                        </Link>
                                    </li>
                                    <li onClick={handleLogout} className="flex hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer justify-start items-center gap-2">
                                        <svg
                                            className="w-6 h-6 ml-2 text-red-500 dark:text-red-600"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"
                                            />
                                        </svg>
                                        <p className="block text-red-500 cursor-pointer px-4 py-2 text-sm hover:text-red-600">Sign out</p>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <button
                            type="button"
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="navbar-user"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 17 14"
                            >
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default TopSidebar;
