export enum CustomModalPosition {
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    BottomCenter = "bottom-center",
    TopLeft = "top-left",
    TopCenter = "top-center",
    TopRight = "top-right",
    CenterLeft = "center-left",
    Center = "center",
    CenterRight = "center-right"
}