import React, { useEffect } from "react";
import { Accounts, Layout, ResetPassword, ResetPasswordEmail,ExchangeRates, Transactions, Documents, Journals } from '@/pages'
import { Routes, Route, Navigate } from "react-router-dom";
import { Routes as AppRoutes, RoutesPathList } from "@/config/routes/Routes";
import {
  Landing, About, Contact, Login, Register, Accounting, Invoicing, Payroll,
  Organizations, ProfileUser, PasswordUser, ActivationSuccess, CompanyDashboard,
  NotFound, Billing, Permissions, Users, CreateCompanyUser, DoubleEntry, Items, Employees,
  Taxes, Contacts, Departments, Categories, Transfers, Reconciliations, Pages
}
  from '@/pages';
import { ProtectedRoute } from "./components";
import './scss/index.scss';
import { useAppDispatch } from "./store";
import { initializeTheme } from "./store/slices/themeSlice";

const componentMapping: { [key: string]: React.FC } = {
  Landing: Landing,
  About: About,
  Contact: Contact,
  Login: Login,
  Register: Register,
  Accounting: Accounting,
  Invoicing: Invoicing,
  ActivationSuccess: ActivationSuccess,
  Payroll: Payroll,
  Organizations: Organizations,
  OrganizationUserProfile: ProfileUser,
  OrganizationUserPassword: PasswordUser,
  ResetPassword: ResetPassword,
  ResetPasswordEmail: ResetPasswordEmail,
  CompanyDasboard: CompanyDashboard,
  Billing: Billing,
  Permissions: Permissions,
  CreateCompanyUser: CreateCompanyUser,
  Users: Users,
  NotFound: NotFound,
  DoubleEntry: DoubleEntry,
  Items: Items,
  Accounts: Accounts,
  Taxes: Taxes,
  Contacts: Contacts,
  Departments: Departments,
  ExchangeRates: ExchangeRates,
  Categories: Categories,
  Employees: Employees,
  Transfers: Transfers,
  Reconciliations: Reconciliations,
  Transactions: Transactions,
  Documents: Documents,
  Journals: Journals,
  Pages: Pages
};

const App: React.FC = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initializeTheme());
  }, [dispatch])

  return (
    <Layout>
      <Routes>
        {
          AppRoutes.map((props) => {
            const Component = componentMapping[props.component];
            return <Route key={props.route} path={props.route} element={
              props.isProtected ?
                <ProtectedRoute>
                  <Component />
                </ProtectedRoute>
                :
                <Component />
            } />
          })
        }
        <Route path={RoutesPathList.RouteCheckMatcher} element={<Navigate to={RoutesPathList.NotFound} replace />} />
      </Routes>
    </Layout>
  )
}

export default App;