import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization, OrganizationSliceState } from "@/types/Organization";
import { getData, saveData } from "@/utils/localStorage";
import { organizationStorageKey } from "@/config/constants/StorageKeys";


const initialOrganizationState: OrganizationSliceState = {
    selectedOrganization: getData<Organization>(organizationStorageKey),
}

const organizationSlice = createSlice({
    name: 'organization',
    initialState: initialOrganizationState,
    reducers: {
        setSelectedOrganization(state, action: PayloadAction<Organization | null>) {
            state.selectedOrganization = action.payload;
            saveData(organizationStorageKey, action.payload)
        }
    }
})

export const { setSelectedOrganization } = organizationSlice.actions
export default organizationSlice

