import React from 'react';
import FormField from '@/components/CustomForm/FormField';
import { ResetPasswordByEmailRequest, ResetPasswordByEmailSchema } from '@/types/ResetPassword';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FormFieldType } from '@/types/FormFieldsType';
import GenericApi from '@/api/genericApi';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { ApiResponse, ApiError } from '@/types/Api';
import { FormError, ValidationErrors } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { useMutation } from '@tanstack/react-query';
import { formatError } from '@/utils/formatError';
import { AxiosError, isAxiosError } from 'axios';
import { Logo } from '@/components';

const ResetPasswordEmail: React.FC = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<ResetPasswordByEmailRequest>({
        resolver: zodResolver(ResetPasswordByEmailSchema),
    });

    const sendResetPasswordEmailMutation = useMutation<ApiResponse<unknown>, ApiError<ValidationErrors>, ResetPasswordByEmailRequest>(
        {
            mutationFn: (data) => GenericApi.put<unknown>(ApiRoutes.RESET_PASSWORD_EMAIL, {
                ...data
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        const errorMessage = 'An error occurred while sending the email'

        if (isAxiosError<FormError>(error)) {
            setError('root', {
                type: 'manual',
                message: error.response?.data?.detail ?? errorMessage
            })
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            setError('root', {
                type: 'manual',
                message: formatError(error, errorMessage)
            })
            return
        }

        //* Set error message from server
        setError('root', {
            type: 'manual',
            message: error?.message ?? errorMessage
        })
    }

    const onSubmit = (data: ResetPasswordByEmailRequest) => {
        sendResetPasswordEmailMutation.mutate(data)
    }

    const containerStyle = "flex justify-center flex-col w-full"

    return (
        <div className="bg-gray-50 dark:bg-gray-900 h-[100dvh]">
            <div className="max-w-screen-xl px-4 py-8 mx-auto">
                <div className="mb-6 text-center">
                    <Logo size={{
                        width: "200px",
                        height: "70px"
                    }} />
                </div>
                <div className="w-full col-span-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0">
                    <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl dark:text-white">
                            Forgot your password?
                        </h1>
                        <p className="font-light text-gray-500 dark:text-gray-400">
                            Just type in your email, and we will send you a link to reset your password!
                        </p>
                        {
                            sendResetPasswordEmailMutation.isError ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{
                                    errors.root?.message
                                }</span>
                            </div> : null
                        }
                        {
                            sendResetPasswordEmailMutation.isSuccess ?
                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                    <span className="block sm:inline">Please check your email!</span>
                                </div> : null
                        }
                        <form className="space-y-4 lg:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                            <FormField<ResetPasswordByEmailRequest>
                                name="email"
                                type={FormFieldType.EMAIL}
                                label="Email"
                                register={register}
                                error={errors.email}
                                placeholder="Enter your email"
                                required={true}
                                valueAsNumber={false}
                                containerStyle={containerStyle}
                                inputStyle='bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                            />
                            <button
                                type="submit"
                                className="w-full flex justify-center items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                {
                                    sendResetPasswordEmailMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : 'Send Email'
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordEmail;
