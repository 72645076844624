import { z, ZodSchema } from "zod";

export type Account = {
    id: number;
    type: string;
    name: string;
    number: string;
    currency: string;
    opening_balance: string;
    bank_name?: string;
    bank_phone?: string;
    bank_address?: string;
}

export type AccountCreateRequest = {
    type: string;
    name: string;
    number: string;
    currency: string;
    opening_balance: string;
    bank_name?: string;
    bank_phone?: string;
    bank_address?: string;
}

export type AccountUpdate = {
    type: string;
    name: string;
    number: string;
    currency: string;
    opening_balance: string;
    bank_name?: string;
    bank_phone?: string;
    bank_address?: string;
}

export const AccountCreateSchema: ZodSchema<AccountCreateRequest> = z.object({
    type: z.string(),
    name: z.string(),
    number: z.string(),
    currency: z.string(),
    opening_balance: z.string(),
    bank_name: z.string().optional(),
    bank_phone: z.string().optional(),
    bank_address: z.string().optional(),
});

export const AccountUpdateSchema: ZodSchema<AccountUpdate> = z.object({
    type: z.string(),
    name: z.string(),
    number: z.string(),
    currency: z.string(),
    opening_balance: z.string(),
    bank_name: z.string().optional(),
    bank_phone: z.string().optional(),
    bank_address: z.string().optional(),
});