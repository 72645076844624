import { ZodType, z } from "zod";

// ORGANIZATION
export type User = {
  id: number;
  email: string;
  password: string;
  is_staff: boolean;
  address: string;
  is_organization: boolean;
  company_name: string;
  vat_no_tic: string;
  he_number: string;
  si_number?: string;
  tax_jurisdiction: string;
  engagement_type: string;
  currency: string;
  multi_currency: string[];
  first_name: string;
  last_name: string;
  mobile: string;
  organization: number;
};

export type UpdateUserOrganization = {
  email: string;
  address: string;
  company_name: string;
  vat_no_tic: string;
  he_number: string;
  si_number?: string;
  tax_jurisdiction: string;
  engagement_type: string;
  currency: string;
  multi_currency?: string[];
}

export type UserCompany = {
  id: number;
  first_name: string;
  last_name: string;
  mobile: string;
  organization: number;
  is_organization: boolean;
};

export type UpdateUser = {
  address: string;
  company_name: string;
  vat_no_tic: string;
  he_number: string;
  si_number?: string;
  tax_jurisdiction: string;
  engagement_type: string;
  currency: string;
  multi_currency?: string[];
};


export type UpdateUserCompany = {
  first_name: string;
  last_name: string;
  mobile: string;
  organization?: number;
  is_organization?: boolean;
};

export const UpdateUserCompanySchema: ZodType<UpdateUserCompany> = z.object({
  first_name: z.string().min(1, {
    message: "Too short first name",
  }),
  last_name: z.string().min(1, {
    message: "Too short last name",
  }),
  mobile: z.string().min(1, {
    message: "Too short mobile",
  }),
  organization: z.number().optional(),
  is_organization: z.boolean().optional(),
});

export const UpdateUserSchema: ZodType<UpdateUser> = z.object({
  address: z.string().min(1, {
    message: "Too short address",
  }),
  company_name: z.string().min(1, {
    message: "Too short company name",
  }),
  vat_no_tic: z.string().min(1, {
    message: "Too short vat no tic",
  }),
  he_number: z.string().min(1, {
    message: "Too short he number",
  }),
  si_number: z.string().optional(),

  tax_jurisdiction: z.string().min(1, {
    message: "Too short tax jurisdiction",
  }),
  engagement_type: z.string().min(1, {
    message: "Too short engagement type",
  }),
  currency: z.string().min(1, {
    message: "Too short currency",
  }),
  multi_currency: z.array(z.string()).optional()
});

export const UpdateUserOrganizationSchema: ZodType<UpdateUserOrganization> = z.object({
  email: z.string().email({
    message: "Invalid email",
  }),
  address: z.string().min(1, {
    message: "Too short address",
  }),
  company_name: z.string().min(1, {
    message: "Too short company name",
  }),
  vat_no_tic: z.string().min(1, {
    message: "Too short vat no tic",
  }),
  he_number: z.string().min(1, {
    message: "Too short he number",
  }),
  si_number: z.string().optional(),
  tax_jurisdiction: z.string().min(1, {
    message: "Too short tax jurisdiction",
  }),
  engagement_type: z.string().min(1, {
    message: "Too short engagement type",
  }),
  currency: z.string().min(1, {
    message: "Too short currency",
  }),
  multi_currency: z.array(z.string()).optional()
});

export type InviteUser = {
  email: string;
};

export const InviteUserSchema: ZodType<InviteUser> = z.object({
  email: z.string().email({
    message: "Invalid email",
  })
});


export type CreateUserCompany = {
  first_name: string;
  last_name: string;
  mobile: string;
  organization?: number;
  is_organization?: boolean;
  email: string;
  password?: string;
};

export const CreateUserCompanySchema: ZodType<CreateUserCompany> = z.object({
  first_name: z.string().min(1, {
    message: "Too short first name",
  }),
  last_name: z.string().min(1, {
    message: "Too short last name",
  }),
  mobile: z.string().min(1, {
    message: "Too short mobile",
  }),
  organization: z.number().optional(),
  is_organization: z.boolean().optional(),
  email: z.string().email({
    message: "Invalid email",
  }),
  password: z.string()
    .min(8, {
      message: "Password must be at least 8 characters long"
    })
    .regex(/[0-9]/, { message: "Password must contain at least one number" })
    .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
    .regex(/[!@#$%^&*(),.?":{}_=+|<>-]/, { message: "Password must contain at least one special character" }),
});