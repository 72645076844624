import GenericApi from '@/api/genericApi';
import { AdaptiveModal, CompanyUserItem } from '@/components';
import { ApiRoutes, composeRoute } from '@/config/routes/ApiRoutes';
import { ApiError, ApiResponse } from '@/types/Api';
import { CustomModalPosition } from '@/types/Modal';
import { Permission, PermissionName, PermissionRequest } from '@/types/Permissions';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { Button } from 'flowbite-react';
import { mapPermissionToNameAndIcon } from '@/utils/utils';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InviteUser, InviteUserSchema, User } from '@/types/User';
import FormField from '@/components/CustomForm/FormField';
import { FormFieldType } from '@/types/FormFieldsType';
import { ValidationErrors, FormError } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { formatError } from '@/utils/formatError';
import { getUser } from '@/utils/localStorage';
import { AxiosError, isAxiosError } from 'axios';
import { useSnackBarAlert } from '@/hooks/useSnackbar';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import LoadingPage from '../LoadingPage';
import { FallbackPage } from '..';
import { CompanyQueryKey, CompanyUsersQueryKey } from '@/config/constants/QueryKeys';
import { Company, CompanyUser } from '@/types/Company';

const Users: React.FC = () => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setError,
    } = useForm<InviteUser>({
        resolver: zodResolver(InviteUserSchema),
    });

    const { selectedCompany } = useSelector((state: RootState) => state.company)

    const user = getUser();
    const [openFilter, setOpenFilter] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    console.log(searchValue)

    const [userModal, setUserModal] = useState(false);
    const { showSnackBar } = useSnackBarAlert()

    const [selectedPermission, setSelectedPermission] = useState<Permission>({
        id: 0,
        read_reports: false,
        edit_accounting_and_payroll: false,
        read_invoicing: false,
        edit_invoicing: false,
    })

    const route = composeRoute(ApiRoutes.COMPANY, selectedCompany?.id ?? null, ApiRoutes.USERS)
    

    const { data: users, isLoading: isLoadingUser, error } = useQuery<ApiResponse<CompanyUser[]>>(
        {
            queryFn: async () => GenericApi.get<CompanyUser[]>(route),
            queryKey: [CompanyUsersQueryKey],
        }
    );

    const { data: companies, isLoading } = useQuery<ApiResponse<Company[]>>(
        {
            queryFn: async () => GenericApi.get<Company[]>(ApiRoutes.COMPANY),
            queryKey: [CompanyQueryKey],
            enabled: !!users?.data
        }
    );

    console.log({
        users,
        companies,
        route
    })

    const inviteUserMutation = useMutation<ApiResponse<User>, ApiError<ValidationErrors>, PermissionRequest>(
        {
            mutationFn: (data) => GenericApi.post<User>(ApiRoutes.INVITE_USER, data),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setUserModal(false)
                showSnackBar('User invitation sent', 'success', {
                    horizontal: 'right',
                    vertical: 'top'
                }, 2000)
                reset()
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        const errorMessage = "An error occurred while invinting user"
        if (isAxiosError<FormError>(error)) {
            setError('root', {
                type: 'manual',
                message: error.response?.data?.detail ?? errorMessage
            })
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            setError('root', {
                type: 'manual',
                message: formatError(error, errorMessage)
            })
            return
        }

        //* Set error message from server
        setError('root', {
            type: 'manual',
            message: error?.message ?? errorMessage
        })
    }


    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }

    const toggleUserModal = () => {
        if (!selectedCompany) {
            showSnackBar('Please select a company', 'error', {
                horizontal: 'right',
                vertical: 'top'
            }, 2000)
            return
        }
        setUserModal(!userModal);
    }

    const onRetry = () => {
        window.location.reload();
    }


    const renderUserItem = () => {
        return users?.data?.map((user) => {
            return <CompanyUserItem key={user.id} user={user} />
        }) 
    }


    if (isLoadingUser || isLoading) return <div className='mt-[4rem]'>
        <LoadingPage />
    </div>;

    const fetchError = error || users?.error || companies?.error;

    if (fetchError) {
        console.error(fetchError);
        return <FallbackPage onRetry={onRetry} />;
    }

    if (!users?.data || !companies?.data) {
        console.error("Data is undefined");
        return <FallbackPage onRetry={onRetry} />;
    }

    const handlePermissionChange = (permissionName: PermissionName) => {
        const key = Object.keys(selectedPermission).find((key) => key === permissionName);

        if (!key) return;

        setSelectedPermission((prev) => ({
            ...prev,
            [key]: !prev[key]
        }))
    }

    const onSubmit = (data: InviteUser) => {

        if(!selectedCompany || !user) {
            return
        }

        inviteUserMutation.mutate({
            company: selectedCompany?.id,
            user: user?.id,
            email: data.email,
            edit_accounting_and_payroll: selectedPermission.edit_accounting_and_payroll,
            edit_invoicing: selectedPermission.edit_invoicing,
            read_invoicing: selectedPermission.read_invoicing,
            read_reports: selectedPermission.read_reports
        })
    }



    return (
        <div className="bg-gray-50 mt-[4rem] dark:bg-gray-900">
            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
                        <div className="flex justify-center items-center gap-4">
                            <form className="flex items-center">
                                <label htmlFor="search" className="sr-only">
                                    Search
                                </label>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg
                                            className="w-4 h-4 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-width="2"
                                                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchValue(e.target.value)} type="search" id="default-search" className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                                </div>
                            </form>
                            <button onClick={toggleFilter} id="filterDropdownButton" data-dropdown-toggle="filterDropdown" className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
                                </svg>
                                Filter
                                <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                </svg>
                                {
                                    openFilter ? <div className="z-50 absolute top-full w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                                        <h6 className="mb-3 text-sm text-start font-medium text-gray-900 dark:text-white">
                                            Date
                                        </h6>
                                        <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">
                                            <li className="flex items-center">
                                                <input id="apple" type="checkbox" value=""
                                                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                    Ascending
                                                </label>
                                            </li>
                                            <li className="flex items-center">
                                                <input id="fitbit" type="checkbox" value=""
                                                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                    Descending
                                                </label>
                                            </li>
                                        </ul>
                                    </div> : null
                                }
                            </button>
                        </div>


                        <button
                            onClick={toggleUserModal}
                            data-modal-toggle="createUserModal"
                            type="button"
                            className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                        >
                            <svg
                                className="h-3.5 w-3.5 mr-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                            >
                                <path
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                />
                            </svg>
                            Invite User
                        </button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-4 py-3 text-right">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderUserItem()}
                        </tbody>
                    </table>
                </div>
            </div>

            <AdaptiveModal
                isOpen={userModal}
                onClose={() => setUserModal(false)}
                position={CustomModalPosition.Center}
                title={<p className='dark:text-white'>Invite user to company <span className='text-primary-500'>{selectedCompany?.name}</span></p>}
            >
                {
                    inviteUserMutation.isError ? <div className="bg-red-100 border mb-3 border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <span className="block sm:inline">{
                            errors.root?.message
                        }</span>
                    </div> : null
                }
                <form className="w-full space-y-4" onSubmit={handleSubmit(onSubmit)}>
                    <FormField<InviteUser>
                        error={errors.email}
                        label="Email"
                        placeholder="Enter email"
                        type={FormFieldType.EMAIL}
                        name='email'
                        register={register}
                        required={true}
                        valueAsNumber={false}
                        inputStyle='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                    />

                    <div className="space-y-2">
                        <p className="dark:text-white">Permissions</p>
                        <div className="flex flex-col gap-6 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md">
                            {Object.values(PermissionName).map((permissionName) => {
                                const { name, icon, id } = mapPermissionToNameAndIcon(permissionName);
                                return (
                                    <div key={id} className="bg-white flex justify-between items-center dark:bg-gray-700 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300">
                                        <p className="text-lg font-semibold dark:text-white">{name}</p>
                                        <div className="w-14">
                                            <Button
                                                onClick={() => handlePermissionChange(permissionName)}
                                                className={`w-full mt-2 rounded-lg transition duration-300 ${selectedPermission[permissionName]
                                                        ? 'bg-green-200 text-white border border-green-500 hover:bg-green-600'
                                                        : 'bg-gray-200 text-gray-800 border border-gray-300 hover:bg-gray-300'
                                                    }`}
                                                color={
                                                    selectedPermission[permissionName] ? 'green' : 'gray'
                                                }
                                            >
                                                {icon}
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="flex justify-center w-full">
                        <Button type='submit' color="blue" className='w-full'>
                            {
                                inviteUserMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : 'Invite user'
                            }
                        </Button>
                    </div>
                </form>
            </AdaptiveModal>

        </div>

    )
}

export default Users
