import { ZodType, z } from "zod"

export type ResetPasswordByEmailRequest = {
    email: string
}

export type ChangeEmailRequest = {
    new_email: string
    confirm_email: string
    password: string
}

export type ResetPasswordRequest = {
    new_password: string
    confirm_password: string
    token?: string
}

export const ResetPasswordByEmailSchema: ZodType<ResetPasswordByEmailRequest> = z.object({
    email: z.string().email({
        message: "Invalid email"
    })
})

export const ResetPasswordSchema: ZodType<ResetPasswordRequest> = z.object({
    new_password: z.string()
        .min(8, {
            message: "Password must be at least 8 characters long"
        })
        .regex(/[0-9]/, { message: "Password must contain at least one number" })
        .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
        .regex(/[!@#$%^&*(),.?":{}_=+|<>-]/, { message: "Password must contain at least one special character" }),
    confirm_password: z.string().min(8, {
        message: "Password must be at least 8 characters long"
    })
}).refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirm_password"],
});

export const ChangeEmailSchema: ZodType<ChangeEmailRequest> = z.object({
    new_email: z.string().email({
        message: "Invalid email"
    }),
    confirm_email: z.string().email({
        message: "Invalid email"
    }),
    password: z.string().min(8, {
        message: "Password must be at least 8 characters long"
    })
}).refine((data) => data.new_email === data.confirm_email, {
    message: "Emails don't match",
});