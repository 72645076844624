import GenericApi from '@/api/genericApi';
import FormField from '@/components/CustomForm/FormField';
import { CurrencyQueryKey } from '@/config/constants/QueryKeys';
import { authStorageUser } from '@/config/constants/StorageKeys';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { RoutesPathList } from '@/config/routes/Routes';
import { useSnackBarAlert } from '@/hooks/useSnackbar';
import { ApiResponse, ApiError } from '@/types/Api';
import { Currency } from '@/types/Currency';
import { ChangeEmailRequest, ChangeEmailSchema } from '@/types/ResetPassword';
import { UpdateUser, UpdateUserCompany, UpdateUserCompanySchema, UpdateUserSchema, User } from '@/types/User';
import { ValidationErrors } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { generateChangeEmailInputs, generateProfileInputs } from '@/utils/generateInputs';
import { getUser, removeData, updateUserData } from '@/utils/localStorage';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'flowbite-react';
import React, { Fragment, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FallbackPage, LoadingPage } from '..';
import { wait } from '@/utils/utils';

const ProfileUser: React.FC = () => {

    const user = getUser();
    const navigate = useNavigate();
    const changeEmailInputs = generateChangeEmailInputs();
    const { showSnackBar } = useSnackBarAlert();
    const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);

    const { data: currencies, isLoading: isLoadingCurrencies, error } = useQuery<ApiResponse<Currency[]>>(
        {
            queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
            queryKey: [CurrencyQueryKey],
        }
    );

    const toggleChangeEmailModal = () => {
        CustomLogger.log('Change email modal toggled')
        setOpenChangeEmailModal(!openChangeEmailModal);
    }

    const handleLogout = () => {
        removeData(authStorageUser);
        navigate(RoutesPathList.Login, { replace: true });
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        
    } = useForm<UpdateUser>({
        resolver: zodResolver(UpdateUserSchema),
        defaultValues: {
            multi_currency: user?.multi_currency ?? []
        }
    });

    const {
        register: registerUserCompany,
        handleSubmit: handleSubmitUserCompany,
        formState: { errors: userCompanyErrors },
    } = useForm<UpdateUserCompany>({
        resolver: zodResolver(UpdateUserCompanySchema),
    });

    const {
        register: registerEmail,
        handleSubmit: handleSubmitEmail,
        formState: { errors: errorsEmail },
    } = useForm<ChangeEmailRequest>({
        resolver: zodResolver(ChangeEmailSchema),
        defaultValues: {
            new_email: '',
            confirm_email: '',
            password: ''
        }
    });

    const updateUserMutation = useMutation<ApiResponse<User>, ApiError<ValidationErrors>, UpdateUser | UpdateUserCompany>(
        {
            mutationFn: (data) => GenericApi.put<User>(`${ApiRoutes.UPDATE_USER}/${user?.id}`, {
                ...data
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                const isUserUpdated = updateUserData(data);
                if (isUserUpdated) {
                    CustomLogger.log('User data updated successfully')
                } else {
                    CustomLogger.error('Failed to update user data')
                    showSnackBar('Failed to update user data', 'error', {
                        horizontal: 'right',
                        vertical: 'top'
                    }, 2000)
                }
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
            }
        }
    )

    const updateEmailMutation = useMutation<ApiResponse<User>, ApiError<ValidationErrors>, ChangeEmailRequest>(
        {
            mutationFn: (data) => GenericApi.put<User>(`${ApiRoutes.CHANGE_EMAIL}/${user?.id}`, {
                new_email: data.new_email,
                password: data.password
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
            }
        }
    )

    const handleUpdateUser = (data: UpdateUser) => {
        CustomLogger.log(data);
        updateUserMutation.mutate(data);
    }

    const onSubmit = (data: UpdateUser) => {
        handleUpdateUser(data);
    }

    const onSubmitEmail = async (data: ChangeEmailRequest) => {
        CustomLogger.log(data);
        const res = await updateEmailMutation.mutateAsync(data);
        if(!res.error){
            showSnackBar('You are about to be logged out', 'success', {
                horizontal: 'right',
                vertical: 'top'
            }, 5000)
            await wait(5000)
            handleLogout();
        }
    }

    const checkAutocomplete = (autocomplete: string | undefined) => {
        if (!autocomplete) {
            return 'on';
        }
        return 'new-password';
    }

    const handleUpdateUserCompany = (data: UpdateUserCompany) => {
        CustomLogger.log(data);
        updateUserMutation.mutate(data);
    }

    const onSubmitUserCompany = (data: UpdateUserCompany) => {
        handleUpdateUserCompany(data);
    }

    const onRetry = () => {
        window.location.reload();
    }

    const getFieldError = (error: any): FieldError | undefined => {
        if (error && "type" in error) {
          return error as FieldError;
        } else if (Array.isArray(error)) {
          return error.find((err) => err !== undefined);
        }
        return undefined;
      };

    if (isLoadingCurrencies) return <div className='mt-[4rem]'>
        <LoadingPage />
    </div>;

    const fetchError = error || currencies?.error;

    if (fetchError) {
        console.error(fetchError);
        return <FallbackPage onRetry={onRetry} />;
    }

    if (!currencies?.data) {
        console.error("Data is undefined");
        return <FallbackPage onRetry={onRetry} />;
    }

    const profileInputs = generateProfileInputs(user, currencies?.data);

    return (
        <Fragment>
            <div className="w-full p-6 mt-16 bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                <h2 className="mb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Profile Information
                </h2>
                {updateUserMutation.isError && (
                    <div className="alert alert-error">
                        A error occurred while updating profile
                    </div>
                )}
                {updateUserMutation.isSuccess && (
                    <div className="alert alert-success">
                        Profile updated successfully
                    </div>
                )}
                <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={
                    user?.is_organization ? handleSubmit(onSubmit) : handleSubmitUserCompany(onSubmitUserCompany)
                }>
                    {
                        profileInputs.map((input, index) => {
                            if (user?.is_organization) {
                                return <FormField<UpdateUser>
                                    key={index}
                                    label={input.label}
                                    required={input.required}
                                    name={input.name}
                                    placeholder={input.placeholder}
                                    register={register}
                                    type={input.type}
                                    multiple={input?.multiple ?? false}
                                    options={input.options}
                                    defaultValue={input?.defaultValue}
                                    valueAsNumber={input.valueAsNumber}
                                    inputStyle={` ${input?.multiple ? 'h-[20rem]' : ''} bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                                    error={getFieldError(errors[input.name as keyof UpdateUser])}
                                />
                            }

                            return <FormField<UpdateUserCompany>
                                key={index}
                                label={input.label}
                                required={input.required}
                                name={input.name}
                                placeholder={input.placeholder}
                                register={registerUserCompany}
                                type={input.type}
                                defaultValue={input?.defaultValue}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle={`bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                                error={userCompanyErrors[input.name as keyof UpdateUserCompany]}
                            />
                        })

                    }
                    <button
                        type="submit"
                        className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                        {
                            updateUserMutation.isPending ?
                                <div className='flex justify-center items-center gap-4'>
                                    Updating
                                    <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg>
                                </div>
                                : 'Update'
                        }
                    </button>
                </form>


            </div>
            <div className="w-full p-6 mt-3 bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                <div className="flex justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                        <h2 className="mb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Change Email
                        </h2>
                        <p className='text-gray-400'>{user?.email}</p>
                    </div>
                    <button onClick={toggleChangeEmailModal} className="ml-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Update Email
                    </button>
                </div>
            </div>

            <Modal
                position={'center'}
                dismissible
                className='h-full flex items-center bg-[rgba(0,0,0,0.4)]'
                show={openChangeEmailModal}
                onClose={() => setOpenChangeEmailModal(false)}>
                <Modal.Header>Update Email</Modal.Header>
                <Modal.Body>
                    {updateEmailMutation.isError && (
                        <div className="alert alert-error">
                            A error occurred while sending email
                        </div>
                    )}
                    {updateEmailMutation.isSuccess && (
                        <div className="alert alert-success">
                            Verification email sent successfully
                        </div>
                    )}
                    <form
                        autoComplete='off'
                        className="space-y-4 lg:mt-5 md:space-y-5"
                        onSubmit={handleSubmitEmail(onSubmitEmail)}
                    >
                        {
                            changeEmailInputs.map((input, index) => (
                                <FormField<ChangeEmailRequest>
                                    key={index}
                                    label={input.label}
                                    required={input.required}
                                    error={errorsEmail[input.name as keyof ChangeEmailRequest]}
                                    name={input.name}
                                    placeholder={input.placeholder}
                                    register={registerEmail}
                                    type={input.type}
                                    valueAsNumber={input.valueAsNumber}
                                    autocomplete={checkAutocomplete(input.autocomplete)}
                                    inputStyle='bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                />))
                        }
                        <button
                            type="submit"
                            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                            {
                                updateEmailMutation.isPending ?
                                    <div className='flex justify-center items-center gap-4'>
                                        Updating
                                        <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg>
                                    </div>
                                    : 'Update Email'
                            }
                        </button>

                    </form>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
};

export default ProfileUser;
