import { ZodType, z } from "zod";

export type Organization = {
    id: number;
    name: string;
    created_at: string;
    owner: number;
    users: unknown[];
};

export type OrganizationSliceState = {
    selectedOrganization: Organization | null;
}

export type OrganizationForm = {
    name: string;
    owner_id?: number;
};

export const OrganizationFormSchema: ZodType<OrganizationForm> = z.object({
    name: z.string().min(2, {
        message: "Too short organization name"
        }),
    owner_id: z.optional(z.number())
});