import { pageStorageKey } from "@/config/constants/StorageKeys";
import { getData, saveData } from "@/utils/localStorage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PageSliceState = {
    id: number | null;
}

const initialPageState: PageSliceState = {
  id: getData<number | null>(pageStorageKey),
}

const pageSlice = createSlice({
  name: "page",
  initialState: initialPageState,
  reducers: {
    setSelectedPage(state, action: PayloadAction<number | null>) {
      state.id = action.payload;
      saveData(pageStorageKey,action.payload)
    },
  },
});

export const { setSelectedPage } = pageSlice.actions;
export default pageSlice;
