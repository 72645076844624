import React from 'react';
import { Logo } from '@/components';

export type FallbackProps = {
  onRetry: () => void;
};

const FallbackPage: React.FC<FallbackProps> = ({ onRetry }) => {
  return (
    <div className="h-screen dark:bg-gray-800 flex flex-col items-center justify-center">
      <Logo size={{ width: '200px', height: '70px' }} className="mb-5" />
      <h1 className="text-3xl dark:text-white font-bold mb-4">Oops! Something went wrong.</h1>
      <p className="text-gray-600 dark:text-gray-200 mb-8">Please try again later.</p>
      <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        onClick={onRetry}
      >
        Retry
      </button>
    </div>
  );
};

export default FallbackPage;
