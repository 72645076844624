import { UseFormRegister, FieldError, FieldValues } from "react-hook-form";

export type FormFieldProps<T extends FieldValues> = {
    type: FormFieldType;
    placeholder: string;
    name: string;
    register: UseFormRegister<T>;
    error: FieldError | undefined;
    valueAsNumber: boolean;
    options?: SelectOptionsType[];
    value?: string;
    defaultValue?: unknown
    readonly?: boolean;
    multiple?: boolean;
};

export type SimpleInputType<T> = {
    type: FormFieldType;
    placeholder: string;
    name: Extract<keyof T, string>;
}

export type SelectOptionsType = {
    value: string;
    label: string;
}


export enum FormFieldType {
    TEXT = "text",
    PASSWORD = "password",
    SELECT = "select",
    EMAIL = "email",
    NUMBER = "number",
    DATE = "date",
    TIME = "time",
    DATETIME_LOCAL = "datetime-local",
    SEARCH = "search",
    TEL = "tel",
    URL = "url",
    COLOR = "color",
    FILE = "file",
    RANGE = "range",
    CHECKBOX = "checkbox",
    RADIO = "radio",
    SUBMIT = "submit",
    RESET = "reset",
    BUTTON = "button",
    HIDDEN = "hidden",
    IMAGE = "image",
    MONTH = "month",
    WEEK = "week",
}