import { ZodType, z } from "zod";
import { User } from "./User";

export type AuthToken = string;
export type RefreshToken = string;

export type LoginRequest = {
    email: string
    password: string
}

export interface TokenResponse {
    access: AuthToken;
    refresh: RefreshToken;
}

export interface AuthResponse extends TokenResponse {
    user: User;
}

export type RegisterRequest = {
    email: string;
    address: string;
    company_name: string;
    vat_no_tic: string;
    he_number: string;
    si_number?: string;
    tax_jurisdiction: string;
    engagement_type: string;
    currency: string;
    multi_currency?: string[]
    password: string
}

export type ChangePasswordRequest = {
    old_password: string
    new_password: string
    confirm_password: string
}

export type ValidFieldNames = "email" | "password";

export const LoginRequestSchema: ZodType<LoginRequest> = z.object({
    email: z.string().email({
        message: "Invalid email"
    }),
    password: z.string().min(8, {
        message: "Password must be at least 8 characters long"
    }),
    remember: z.boolean()
})

export const RegisterRequestSchema: ZodType<RegisterRequest> = z.object({
    email: z.string().email({
        message: "Invalid email"
    }),
    password: z.string()
        .min(8, {
            message: "Password must be at least 8 characters long"
        })
        .regex(/[0-9]/, { message: "Password must contain at least one number" })
        .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
        .regex(/[!@#$%^&*(),.?":{}_=+|<>-]/, { message: "Password must contain at least one special character" }),
    address: z.string().min(1, {
        message: "Too short address"
    }),
    company_name: z.string().min(1, {
        message: "Too short company name"
    }),
    vat_no_tic: z.string().min(1, {
        message: "Too short vat number"
    }),
    he_number: z.string().min(1, {
        message: "Too short he number"
    }),
    si_number: z.string().optional(),
    tax_jurisdiction: z.string().min(1, {
        message: "Too short tax jurisdiction"
    }),
    engagement_type: z.string().min(1, {
        message: "Too short engagement type"
    }),
    currency: z.string().min(1, {
        message: "Too short currency"
    }),
    multi_currency: z.array(z.string()).optional()
})

export const ChangePasswordRequestSchema: ZodType<ChangePasswordRequest> = z.object({
    old_password: z.string().min(8, {
        message: "Password must be at least 8 characters long"
    }),
    new_password: z.string()
        .min(8, {
            message: "Password must be at least 8 characters long"
        })
        .regex(/[0-9]/, { message: "Password must contain at least one number" })
        .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
        .regex(/[!@#$%^&*(),.?":{}_=+|<>-]/, { message: "Password must contain at least one special character" }),
    confirm_password: z.string().min(8, {
        message: "Password must be at least 8 characters long"
    })

}).refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirm_password"],
});


