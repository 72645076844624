import { ThemeSliceState } from "@/types/Theme";
import { getCurrentTheme, saveData } from "@/utils/localStorage";
import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "@/types/Theme";
import { themeStorageKey } from "@/config/constants/StorageKeys";

const initialTheme: ThemeSliceState  = {
    theme: getCurrentTheme(),
}

const themeSlice = createSlice({
    name: 'theme',
    initialState: initialTheme,
    reducers: {
        changeTheme(state) {
            const themeToSet = state.theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
            console.log(themeToSet);
            document.body.classList.remove(state.theme);
            state.theme = themeToSet;
            document.body.classList.add("theme-transition");
            document.body.classList.add(themeToSet);
            saveData(themeStorageKey, themeToSet);
            setTimeout(() => {
                document.body.classList.remove("theme-transition");
            }, 2000);
        },
        initializeTheme(state) {
            const theme = getCurrentTheme();
            state.theme = theme;
            document.body.classList.add(theme);
        }
    },
})


export default themeSlice
export const { changeTheme, initializeTheme } = themeSlice.actions