import { ValidationErrors } from "@/types/ValidationError";
import { AxiosError } from "axios";

export const formatError = (detail: AxiosError<ValidationErrors>, message: string) => {
    const firstError = detail.response?.data.detail[0];
    const getLastLoc = firstError?.loc[firstError?.loc?.length - 1];

    if (!firstError || !getLastLoc) {
        return message
    }

    return `${firstError.msg}: ${getLastLoc}`;
}